import { ArrowForward, Close } from '@material-ui/icons';
import { Button, Form, Switch, Tabs } from 'antd';
import Loading from 'components/Basic/Loading';
import ModalDlg from 'components/Basic/ModalDlg';
import toast from 'components/Basic/Toast';
import { useUpdateRewardMutation } from 'core/rewards/RewarsService';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import TabContent from './TabContent';
import { genetateDefaultState } from './tools/generateDefaultState';
import { generatePayload } from './tools/generatePayload';
import { Dayjs } from 'dayjs';
import ConfirmDeliveryModal from './ConfirmDeliveryModal';
import { isDateRangeInSelectedDays, isValidDateRange } from '../Offers/tools/checkValidDates';

const DELIVERY_CHECK_ID = 'only_delivery';

type RewardsViewModalType = {
  rewardInfo: any;
  categories: any;
  isShowModal: boolean;
  current: number;
  pageSize: number;
  onShowModal: (e: boolean) => void;
  draftRequest: () => void;
  offersRequest: (c: number, s: number) => void;
};

type Inner = {
  id: string;
  text: string;
};

export type StateType = {
  activeTab: number;
  isActive: boolean;
  locationType: string;
  locations: any;
  lowerList: Array<Inner>;
  upperList: Array<Inner>;
  subtype: any;
  rewardId: number;
  productId: number;
  sarId: number;
  name: string;
  price: number;
  sku: number;
  image: string;
  weight: string;
  lastWeight: number;
  description: string;
  terms: string;
  campaign: number;
  isSetupValid: boolean;
  isSubtypeValid: boolean;
  isFilterValid: boolean;
  isLocationValid: boolean;
  isDatesValid: boolean;
  validationDates: boolean | string;
  rewardSchedule: {
    isMonday: boolean;
    isTuesday: boolean;
    isWednesday: boolean;
    isThursday: boolean;
    isFriday: boolean;
    isSaturday: boolean;
    isSunday: boolean;
    startDate: Dayjs | null;
    startTime: Dayjs | null;
    endDate: Dayjs | null;
    endTime: Dayjs | null;
    dailyStartTime: Dayjs | null;
    dailyEndTime: Dayjs | null;
  };
  isRedemptionValid: boolean;
  expiration: number;
};

const RewardsViewModal: React.FC<RewardsViewModalType> = ({
  rewardInfo,
  isShowModal,
  categories,
  current,
  pageSize,
  onShowModal,
  offersRequest,
}) => {
  const defaultState = genetateDefaultState(rewardInfo?.id, rewardInfo);
  const [state, setState] = useState<StateType>({
    ...defaultState,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const intl = useIntl();
  const history = useHistory();
  const [updateReward] = useUpdateRewardMutation();
  const onCloseRewardModal = () => {
    onShowModal(false);
    history.replace('/dashboard/deals/rewards');
  };
  const [isShowDeliveryModal, setShowDeliveryModal] = useState<boolean>(false);

  const tabCount = 5;

  const tabNames = [
    <>
      {intl.formatMessage({ id: 'Product setup' })}
      {!state.isSetupValid && <span className="red_dot" />}
    </>,
    <>
      {intl.formatMessage({ id: 'Subtype' })}
      {!state.isSubtypeValid && <span className="red_dot" />}
    </>,
    <>
      {intl.formatMessage({ id: 'Filters' })}
      {!state.isFilterValid && <span className="red_dot" />}
    </>,
    <>
      {intl.formatMessage({ id: 'Dates' })}
      {!state.isDatesValid && <span className="red_dot" />}
    </>,
    <>
      {intl.formatMessage({ id: 'Redemptions' })}
      {!state.isRedemptionValid && <span className="red_dot" />}
    </>,
    <>
      {intl.formatMessage({ id: 'Location' })}
      {!state.isLocationValid && <span className="red_dot" />}
    </>,
  ];

  const handleTabClick = (index: number) => {
    tabNames.forEach((_, id) => id < index && validateFields(id));
    setState(prev => ({ ...prev, activeTab: index }));
  };

  const callbackTabClicked = (key: string) => {
    handleTabClick(+key);
  };

  const onControllerClick = (action: any) => {
    if (state?.activeTab >= 0 && state?.activeTab <= tabCount) {
      if (action === 'next') {
        validateFields();
        const newActiveTab = +state.activeTab + 1;
        setState(prev => ({ ...prev, activeTab: newActiveTab <= tabCount ? newActiveTab : tabCount }));
      } else {
        const newActiveTab = +state.activeTab - 1;
        setState(prev => ({ ...prev, activeTab: newActiveTab >= 0 ? newActiveTab : 0 }));
      }
    }
  };
  const validateFields = (id?: number) => {
    const caseData = id !== undefined && id >= 0 ? id : state.activeTab;

    switch (+caseData) {
      case 0:
        const isSetupValid =
          state.description?.length > 0 &&
          state.terms?.length > 0 &&
          Number(state.weight) > 0 &&
          Number(state.weight) < 2147483648 &&
          state.campaign > 0;
        setState(prev => ({ ...prev, isSetupValid }));
        return isSetupValid;
      case 1:
        const isSubtypeValid = state.subtype > 0;
        setState(prev => ({ ...prev, isSubtypeValid }));
        return isSubtypeValid;
      case 2:
        const isFilterValid = !!state.lowerList?.length;
        setState(prev => ({ ...prev, isFilterValid }));
        return isFilterValid;
      case 3:
        const { rewardSchedule } = state;
        const selectedDays = [
          rewardSchedule.isMonday,
          rewardSchedule.isTuesday,
          rewardSchedule.isWednesday,
          rewardSchedule.isThursday,
          rewardSchedule.isFriday,
          rewardSchedule.isSaturday,
          rewardSchedule.isSunday,
        ];
        const daysValid =
          rewardSchedule.isMonday ||
          rewardSchedule.isTuesday ||
          rewardSchedule.isWednesday ||
          rewardSchedule.isThursday ||
          rewardSchedule.isFriday ||
          rewardSchedule.isSaturday ||
          rewardSchedule.isSunday;

        const timeFieldsValid =
          !!rewardSchedule.startDate &&
          !!rewardSchedule.startTime &&
          !!rewardSchedule.endDate &&
          !!rewardSchedule.endTime &&
          !!rewardSchedule.dailyStartTime &&
          !!rewardSchedule.dailyEndTime;

        const weekDaysCheck = isDateRangeInSelectedDays(rewardSchedule.startDate, rewardSchedule.endDate, selectedDays);

        const allDateCheck = isValidDateRange(
          rewardSchedule.startDate,
          rewardSchedule.endDate,
          rewardSchedule.startTime,
          rewardSchedule.endTime,
          rewardSchedule.dailyStartTime,
          rewardSchedule.dailyEndTime,
        );
        const periodsValid = typeof weekDaysCheck === 'string' || typeof allDateCheck === 'string' ? false : true;

        const validationDates = allDateCheck;
        const isDatesValid = !!(daysValid && timeFieldsValid && periodsValid);
        setState(prev => ({ ...prev, validationDates }));
        setState(prev => ({ ...prev, isDatesValid }));
        return isDatesValid;
      case 4:
        const isRedemptionValid = state.expiration > 0 && state.expiration <= 60; // must not exceed 1 hour
        setState(prev => ({ ...prev, isRedemptionValid }));
        return isRedemptionValid;
      case 5:
        const isLocationValid =
          state?.locationType === 'everywhere'
            ? !!state?.locationType
            : !!(state?.locationType && state.locations?.length > 0);

        setState(prev => ({ ...prev, isLocationValid }));
        return isLocationValid;
      default:
        return false;
    }
  };

  const checkIfRewardCanBeSaved = () => {
    const isTabsValid = tabNames.map((_, index) => validateFields(index)).includes(false);
    const deliveryId = state?.lowerList?.find((item: any) => item.id === DELIVERY_CHECK_ID);
    if (isTabsValid) {
      toast.error({ title: intl.formatMessage({ id: 'Updating reward is failure' }) });
    } else if (deliveryId) {
      setShowDeliveryModal(true);
    } else saveReward();
  };

  const saveReward = async () => {
    setLoading(true);
    try {
      const payload = generatePayload(state);
      await updateReward({ data: payload, id: rewardInfo?.id }).unwrap();
      offersRequest(current, pageSize);
      toast.success({ title: intl.formatMessage({ id: 'Reward is updated successfully' }) });
      onCloseRewardModal();
    } catch (error) {
      toast.error({ title: intl.formatMessage({ id: 'Updating reward is failure' }) });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isShowModal) {
      tabNames.forEach((_, index) => validateFields(index));
    }
  }, [isShowModal]);

  return (
    <>
      <ModalDlg
        className="rewards_view_modal"
        visible={isShowModal}
        width={430}
        handleCancel={onCloseRewardModal}
        isCenter
      >
        <>
          <div className="modal_header">
            <div className="name_wrapper">
              <h3>
                <FormattedMessage id={rewardInfo?.id ? 'Edit Reward' : ''} />
              </h3>
              {rewardInfo?.id && (
                <div className="name_wrapper_id">
                  <FormattedMessage id="OfferId" values={{ id: rewardInfo?.id }} />
                </div>
              )}
              <div className="close_modal" onClick={onCloseRewardModal}>
                <Close />
              </div>
            </div>
          </div>
          <div className="new_rewards_form_wrapper">
            <Tabs
              defaultActiveKey="0"
              tabPosition={'top'}
              onTabClick={callbackTabClicked}
              className="offers_tab"
              popupClassName="tabs_more_menu"
              activeKey={`${state.activeTab}`}
              items={tabNames.map((item, i) => {
                const id = String(i);
                return {
                  label: item,
                  key: id,
                };
              })}
            />
            <Form className="basic_info_form">
              <div className="tab_content">
                <TabContent state={state} setState={setState} index={state.activeTab} />
              </div>
              <Form.Item>
                <>
                  <div className="btn_arrows">
                    <Button
                      className="arrow_left"
                      onClick={() => onControllerClick('prev')}
                      style={+state.activeTab === 0 ? { visibility: 'hidden' } : {}}
                    >
                      <ArrowForward style={{ transform: 'rotate(180deg)' }} />
                      <FormattedMessage id={'Prev'} />
                    </Button>
                    <Button
                      className="arrow_right"
                      onClick={() => onControllerClick('next')}
                      style={+state.activeTab === tabCount ? { visibility: 'hidden' } : {}}
                    >
                      <FormattedMessage id={'Next'} />
                      <ArrowForward />
                    </Button>
                  </div>
                  <div className="side_btns_cat">
                    <div className="aditional_detail">
                      <p id="campaign_form_switcher_label">
                        <FormattedMessage id="Active" />
                      </p>
                      <Switch
                        checked={state.isActive}
                        onChange={checked => setState(prev => ({ ...prev, isActive: checked }))}
                        id="campaign_form_switcher"
                      />
                    </div>
                    <div className="btn_wrapper_offer">
                      {/* <Button className="save_sar_btn" disabled={disabledPreveiw()} onClick={onShowPreviewModal}>
                        <FormattedMessage id={'Preview'} />
                        <RemoveRedEyeOutlined />
                      </Button> */}
                      <Button className="save_sar_btn" disabled={loading} onClick={checkIfRewardCanBeSaved}>
                        <FormattedMessage id={!rewardInfo?.id ? 'Save' : 'Save changes'} />
                        <ArrowForward />
                      </Button>
                    </div>
                  </div>
                </>
              </Form.Item>
            </Form>
          </div>
        </>
      </ModalDlg>
      {loading && <Loading visible={loading} />}
      <ConfirmDeliveryModal
        isOpenModal={isShowDeliveryModal}
        onCloseModal={() => setShowDeliveryModal(false)}
        data={rewardInfo}
        onConfirm={() => {
          saveReward();
          setShowDeliveryModal(false);
        }}
      />
    </>
  );
};

export default RewardsViewModal;
