import React, { useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import ModalDlg from 'components/Basic/ModalDlg';
import { Close } from '@material-ui/icons';
import { useLazyGetSingleItemQuery } from 'core/catalog/CatalogService';
import { useAppSelector } from 'utilities/redux';
import Loading from 'components/Basic/Loading';
import placeHolderImg from 'assets/img/placeholder.svg';
import { getProductInfo } from 'utilities/common';
import { Img } from 'react-image';

type ConfirmDeliveryModalType = {
  isOpenModal: boolean;
  onCloseModal: () => void;
  data: any;
  onConfirm: () => void;
};

const ConfirmDeliveryModal: React.FC<ConfirmDeliveryModalType> = ({ isOpenModal, onCloseModal, data, onConfirm }) => {
  const intl = useIntl();
  const [getProductItem, { data: item, isError: error, isLoading: loading, isFetching: fetching }] =
    useLazyGetSingleItemQuery();
  const { prices, sizes } = useAppSelector(state => state.catalogReducer);

  useEffect(() => {
    if (isOpenModal) {
      getItemData();
    }
  }, [isOpenModal]);

  const getItemData = async () => {
    try {
      await getProductItem({ id: data.productId }).unwrap();
    } catch (error) {
      console.log(error);
    }
  };
  const getProduct = (data: any) => {
    return getProductInfo(data, prices, sizes);
  };
  return (
    <>
      <ModalDlg
        className="reward_delivery_view_modal"
        visible={isOpenModal}
        width={420}
        handleCancel={onCloseModal}
        isCenter
      >
        <>
          <div className="modal_header">
            <div className="name_wrapper">
              <h3>
                {intl.formatMessage({
                  id: 'The configured reward involves using the product within the delivery flow.',
                })}
              </h3>

              <span className="description">
                {intl.formatMessage({
                  id: 'Please check if the product configured in the Voucherify catalog matches the product from the Delivery catalog.',
                })}
              </span>

              <div className="close_modal" onClick={onCloseModal}>
                <Close />
              </div>
            </div>
          </div>
          <div className="product_container">
            <div className="product_wrapper">
              <p className="catalog">{intl.formatMessage({ id: 'VR catalog' })}</p>
              <div className="info">
                <p className="id">SAR ID: {data.sarId}</p>
                <p className="name">{data?.name}</p>
                <div className="image1">
                  <img src={data?.image1} alt="#" />
                </div>
              </div>
            </div>
            <div className="product_wrapper">
              <p className="catalog">{intl.formatMessage({ id: 'Delivery catalog' })}</p>
              <div className="info">
                <p className="id">ID: {data?.productId}</p>
                {item && (
                  <>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: item.name,
                      }}
                      className="name"
                    />
                    <div className="image1">
                      <Img
                        src={
                          item.productCode !== undefined
                            ? `${process.env.REACT_APP_S3}/${item.channel === 'APP' ? 'customer' : 'ots'}/${
                                item.productCode > 0 ? item.productCode : getProduct(item).productCode
                              }.jpg`
                            : placeHolderImg
                        }
                        alt="#"
                      />
                    </div>
                  </>
                )}
                {error && (
                  <p className="product_error">{intl.formatMessage({ id: 'Failed to fetch product data.' })}</p>
                )}
                {(loading || fetching) && <Loading visible={loading || fetching} />}
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="btn_wrapper">
              <p onClick={onCloseModal}>
                <FormattedMessage id="Cancel" />
              </p>
              <Button className="save_button" onClick={onConfirm} disabled={error || loading || fetching}>
                <FormattedMessage id="Confirm" />
              </Button>
            </div>
          </div>
        </>
      </ModalDlg>
    </>
  );
};

export default ConfirmDeliveryModal;
