import { sliceDateTimeToDayjsUtcString, sliceTimeToUtcWithBaseDate } from 'utilities/transformDateTime';
import { StateType } from '../RewardViewModal';
export const generatePayload = (rewardData: StateType) => {
  const data = {
    campaignId: rewardData.campaign,
    image1: rewardData.image,
    locationType: rewardData.locationType,
    isActive: rewardData.isActive,
    terms: rewardData.terms.trimEnd(),
    description: rewardData.description.trimEnd(),
    weight: Number(rewardData.weight),
    contentTags: rewardData.lowerList.map((item: any) => ({ type: item.id })),
    subtabs: [rewardData.subtype],
    restaurantIds: [...rewardData.locations],
    schedule: {
      startDate: sliceDateTimeToDayjsUtcString(rewardData.rewardSchedule?.startDate),
      startTime: sliceTimeToUtcWithBaseDate(rewardData.rewardSchedule?.startTime),
      endDate: sliceDateTimeToDayjsUtcString(rewardData.rewardSchedule?.endDate),
      endTime: sliceTimeToUtcWithBaseDate(rewardData.rewardSchedule?.endTime),
      dailyStartTime: sliceTimeToUtcWithBaseDate(rewardData.rewardSchedule?.dailyStartTime),
      dailyEndTime: sliceTimeToUtcWithBaseDate(rewardData.rewardSchedule?.dailyEndTime),
      isMonday: rewardData.rewardSchedule?.isMonday,
      isTuesday: rewardData.rewardSchedule?.isTuesday,
      isWednesday: rewardData.rewardSchedule?.isWednesday,
      isThursday: rewardData.rewardSchedule?.isThursday,
      isFriday: rewardData.rewardSchedule?.isFriday,
      isSaturday: rewardData.rewardSchedule?.isSaturday,
      isSunday: rewardData.rewardSchedule?.isSunday,
    },
    redemptionConfiguration: {
      expiration: rewardData?.expiration,
    },
  };
  return data;
};
