import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input, Button, Select, DatePicker } from 'antd';
import { Refresh, Search, ArrowDropDown, DateRange } from '@material-ui/icons';

const { Option } = Select;

interface IProps {
  sorts: Object[];
  statuses: Object[];
  tabs: Object[];
  categories: Object[];
  campaigns: Object[];
  searchText: string;
  selectedStatus: string;
  selectedTab: string;
  selectedCategory: string;
  selectedCompaign: string;
  selectedSortStatus: string;
  onSelectSort: (value: string) => void;
  onSelectStatus: (value: string) => void;
  onSelectCategory: (value: string) => void;
  onSelectTab: (value: string) => void;
  onSelectCampaign: (value: string) => void;
  onChangeStartDate: (value: string) => void;
  onChangeEndDate: (value: string) => void;
  onChangeSearch: (e: any) => void;
  onApplyClick: () => void;
}

const OffersFilter: FC<IProps> = ({
  sorts,
  statuses,
  tabs,
  categories,
  campaigns,
  searchText,
  selectedStatus,
  selectedTab,
  selectedCategory,
  selectedCompaign,
  selectedSortStatus,
  onSelectSort,
  onSelectStatus,
  onSelectCategory,
  onSelectTab,
  onSelectCampaign,
  onChangeStartDate,
  onChangeEndDate,
  onChangeSearch,
  onApplyClick,
}: IProps) => {
  const intl = useIntl();

  const sortOptions = sorts.map((item: any) => {
    let isDisabled = false;

    if (selectedStatus === 'false' || selectedStatus === 'true') {
      isDisabled =
        item.value !== 'Ndesc' &&
        item.value !== 'Nasc' &&
        item.value !== 'Idesc' &&
        item.value !== 'Iasc' &&
        item.value !== 'Dasc' &&
        item.value !== 'Ddesc' &&
        item.value !== 'Wasc' &&
        item.value !== 'Wdesc';
    }

    return (
      <Option
        value={item.value}
        key={item.label}
        disabled={isDisabled}
        style={{ fontFamily: 'Lato', color: '#242c40' }}
      >
        {item.label}
      </Option>
    );
  });

  const filterOptions = statuses.map((item: any) => {
    let isDisabled = false;

    if (selectedSortStatus === 'Sdesc' || selectedSortStatus === 'Sasc') {
      isDisabled = item.value !== '';
    }

    return (
      <Option
        value={item.value}
        key={item.label}
        disabled={isDisabled}
        style={{ fontFamily: 'Lato', color: '#242c40' }}
      >
        {item.label}
      </Option>
    );
  });

  const onStartDateChange = (date: any, dateString: string) => {
    onChangeStartDate(dateString);
  };

  const onEndDateChange = (date: any, dateString: string) => {
    onChangeEndDate(dateString);
  };

  return (
    <div className="offers_filter_layout" id="campaign_filter_layout">
      <div className="availabilities_containers">
        <Select
          id="campaign_status_filter"
          className="availability_by"
          suffixIcon={<ArrowDropDown />}
          placeholder={intl.formatMessage({ id: 'Status' })}
          value={selectedStatus}
          onChange={onSelectStatus}
          dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
        >
          {filterOptions}
        </Select>
        <Select
          id="campaign_status_filter"
          className="availability_by"
          suffixIcon={<ArrowDropDown />}
          placeholder={intl.formatMessage({ id: 'Tab' })}
          value={selectedTab}
          onChange={onSelectTab}
          dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
        >
          <Option value="" key="allTabs" style={{ fontFamily: 'Lato', color: '#242c40' }}>
            {intl.formatMessage({ id: 'All tabs' })}
          </Option>
          {tabs &&
            tabs.map((item: any) => (
              <Option value={item.name} key={item.id}>
                {item.name}
              </Option>
            ))}
        </Select>
        <Select
          className="availability_by"
          suffixIcon={<ArrowDropDown />}
          placeholder={intl.formatMessage({ id: 'Category' })}
          onChange={onSelectCategory}
          value={selectedCategory}
          dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
        >
          <Option value="" key="allCategories">
            {intl.formatMessage({ id: 'All categories' })}
          </Option>
          {categories &&
            categories.map((item: any) => (
              <Option value={item.name} key={item.id}>
                {item.name}
              </Option>
            ))}
        </Select>
        <Select
          className="availability_by"
          suffixIcon={<ArrowDropDown />}
          placeholder={intl.formatMessage({ id: 'Campaign' })}
          onChange={onSelectCampaign}
          value={selectedCompaign}
          dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
        >
          <Option value="" key="allCategories">
            {intl.formatMessage({ id: 'All campaigns' })}
          </Option>
          {campaigns &&
            campaigns.map((item: any) => (
              <Option value={item.name} key={item.id}>
                {item.name}
              </Option>
            ))}
        </Select>
        <DatePicker
          className="start_date"
          placeholder="Fecha de inicio"
          onChange={onStartDateChange}
          suffixIcon={<DateRange />}
        />
        <DatePicker
          className="start_date"
          placeholder="Fecha límite"
          onChange={onEndDateChange}
          suffixIcon={<DateRange />}
        />
        <Select
          className="sort_by"
          suffixIcon={<ArrowDropDown />}
          placeholder={intl.formatMessage({ id: 'Sort by' })}
          onChange={onSelectSort}
          dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
        >
          {sortOptions}
        </Select>
        <Button id="campaign_apply_filters_btn" className="apply_filters_btn" onClick={onApplyClick}>
          <FormattedMessage id="Apply filters" />
          <Refresh />
        </Button>
      </div>
      <div className="search_container">
        <Input
          suffix={<Search />}
          placeholder={'Buscar oferta'}
          value={searchText}
          onChange={onChangeSearch}
          onKeyDown={onChangeSearch}
          id="campaign_search_input"
        />
      </div>
    </div>
  );
};

export default OffersFilter;
