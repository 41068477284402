import React from 'react';
import Location from './Location';
import Filters from './Filter';
import Subtype from './Subtype';
import { StateType } from './RewardViewModal';
import Setup from './Setup';
import Dates from './Dates';
import Redemption from './Redemption';

type TabContentType = {
  state: StateType;
  setState: (e: any) => void;
  index: number;
};

const animations = [
  { id: 'flare', text: 'Flare' },
  { id: 'snow', text: 'Snow' },
  { id: 'confetti_effect', text: 'Confetti Effect' },
  { id: 'only_restaurant', text: 'Only Restaurant' },
];

const TabContent: React.FC<TabContentType> = ({ state, index, setState }) => {
  const activeTab = Number(index);
  return (
    <>
      {activeTab === 5 && (
        <Location
          isLocation={state.locationType}
          setIsLocation={value => setState((prev: StateType) => ({ ...prev, locationType: value }))}
          setSelectedRestaurant={value => setState((prev: StateType) => ({ ...prev, locations: value }))}
          selectedRestaurant={state.locations}
          isValid={state.isLocationValid}
        />
      )}
      {activeTab === 4 && (
        <Redemption
          isValid={state.isRedemptionValid}
          minutes={state.expiration}
          setMinutes={value => setState((prev: StateType) => ({ ...prev, expiration: value }))}
        />
      )}
      {activeTab === 3 && (
        <Dates
          setIsMonday={value =>
            setState((prev: StateType) => ({ ...prev, rewardSchedule: { ...prev.rewardSchedule, isMonday: value } }))
          }
          isMonday={state.rewardSchedule?.isMonday}
          setIsTuesday={value =>
            setState((prev: StateType) => ({ ...prev, rewardSchedule: { ...prev.rewardSchedule, isTuesday: value } }))
          }
          isTuesday={state.rewardSchedule?.isTuesday}
          setIsWednesday={value =>
            setState((prev: StateType) => ({ ...prev, rewardSchedule: { ...prev.rewardSchedule, isWednesday: value } }))
          }
          isWednesday={state.rewardSchedule?.isWednesday}
          setIsThursday={value =>
            setState((prev: StateType) => ({ ...prev, rewardSchedule: { ...prev.rewardSchedule, isThursday: value } }))
          }
          isThursday={state.rewardSchedule?.isThursday}
          setIsFriday={value =>
            setState((prev: StateType) => ({ ...prev, rewardSchedule: { ...prev.rewardSchedule, isFriday: value } }))
          }
          isFriday={state.rewardSchedule?.isFriday}
          setIsSunday={value =>
            setState((prev: StateType) => ({ ...prev, rewardSchedule: { ...prev.rewardSchedule, isSunday: value } }))
          }
          isSunday={state.rewardSchedule?.isSunday}
          onStartDate={value =>
            setState((prev: StateType) => ({ ...prev, rewardSchedule: { ...prev.rewardSchedule, startDate: value } }))
          }
          onStartTime={value =>
            setState((prev: StateType) => ({ ...prev, rewardSchedule: { ...prev.rewardSchedule, startTime: value } }))
          }
          onEndDate={value =>
            setState((prev: StateType) => ({ ...prev, rewardSchedule: { ...prev.rewardSchedule, endDate: value } }))
          }
          onEndTime={value =>
            setState((prev: StateType) => ({ ...prev, rewardSchedule: { ...prev.rewardSchedule, endTime: value } }))
          }
          onDailyStartTime={value =>
            setState((prev: StateType) => ({
              ...prev,
              rewardSchedule: { ...prev.rewardSchedule, dailyStartTime: value },
            }))
          }
          onDailyEndTime={value =>
            setState((prev: StateType) => ({
              ...prev,
              rewardSchedule: { ...prev.rewardSchedule, dailyEndTime: value },
            }))
          }
          startDate={state.rewardSchedule.startDate}
          startTime={state.rewardSchedule.startTime}
          endDate={state.rewardSchedule.endDate}
          endTime={state.rewardSchedule.endTime}
          dailyStartTime={state.rewardSchedule.dailyStartTime}
          dailyEndTime={state.rewardSchedule.dailyEndTime}
          setIsSaturday={value =>
            setState((prev: StateType) => ({ ...prev, rewardSchedule: { ...prev.rewardSchedule, isSaturday: value } }))
          }
          isSaturday={state.rewardSchedule?.isSaturday}
          isValid={state.isDatesValid}
          validationDates={state.validationDates}
        />
      )}
      {activeTab === 2 && (
        <Filters
          lowerList={state.lowerList}
          setLowerList={value => setState((prev: StateType) => ({ ...prev, lowerList: value }))}
          upperList={state.upperList}
          setUpperList={value => setState((prev: StateType) => ({ ...prev, upperList: value }))}
          isValid={state.isFilterValid}
        />
      )}
      {activeTab === 1 && (
        <Subtype
          isValid={state.isSubtypeValid}
          selectedSubtype={state.subtype}
          setSelectedSubtype={value => setState((prev: StateType) => ({ ...prev, subtype: value }))}
        />
      )}
      {activeTab === 0 && (
        <Setup
          productId={state.productId}
          sarId={state.sarId}
          name={state.name}
          price={state.price}
          sku={state.sku}
          image={state.image}
          weight={state.weight}
          lastWeight={state.lastWeight}
          description={state.description}
          terms={state.terms}
          campaign={state.campaign}
          isValid={state.isSetupValid}
          setWeight={value => setState((prev: StateType) => ({ ...prev, weight: value }))}
          setDescription={value => setState((prev: StateType) => ({ ...prev, description: value }))}
          setTerms={value => setState((prev: StateType) => ({ ...prev, terms: value }))}
          setCampaign={value => setState((prev: StateType) => ({ ...prev, campaign: value }))}
        />
      )}
    </>
  );
};

export default TabContent;
