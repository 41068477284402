import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import TabContent from './TabContent';
import { Tabs } from 'antd';

const Configuration: React.FC = () => {
  const intl = useIntl();
  const [state, setState] = useState<any>({
    activeTab: 0,
  });

  const tabNames = [<>{intl.formatMessage({ id: 'Deals' })}</>, <>{intl.formatMessage({ id: 'Rewards' })}</>];

  const handleTabClick = (index: number) => {
    tabNames.forEach((_, id) => id < index);
    setState((prev: any) => ({ ...prev, activeTab: index }));
  };

  const callbackTabClicked = (key: string) => {
    handleTabClick(+key);
  };

  return (
    <div>
      <Tabs
        defaultActiveKey="0"
        tabPosition={'top'}
        onTabClick={callbackTabClicked}
        className="configuration_tabs"
        activeKey={`${state.activeTab}`}
        items={tabNames.map((item, i) => {
          const id = String(i);
          return {
            label: item,
            key: id,
          };
        })}
      />
      <div className="tab_content">
        <TabContent index={state.activeTab} />
      </div>
    </div>
  );
};

export default Configuration;
