import { ArrowForward } from '@mui/icons-material';
import { Button, Input } from 'antd';
import { useLazyGetLoyaltyConfigurationQuery, useUpdateLoyaltyConfigurationMutation } from 'core/rewards/RewarsService';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import toast from 'components/Basic/Toast';

const RewardsConfiguration: React.FC = () => {
  const [getLoyalty] = useLazyGetLoyaltyConfigurationQuery();
  const [updateLoyalty] = useUpdateLoyaltyConfigurationMutation();
  const intl = useIntl();

  const [state, setState] = useState<any>({
    accumulatedPointsNumber: '',
    maxPointsAmount: '',
  });

  const onChangeAccumulatedPointsNumber = (event: any) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, '').toUpperCase();
    setState({ ...state, accumulatedPointsNumber: inputValue });
  };

  const onChangeMaxPointsAmount = (event: any) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, '').toUpperCase();
    setState({ ...state, maxPointsAmount: inputValue });
  };

  const getData = async () => {
    try {
      const configuration = await getLoyalty({}).unwrap();

      setState({
        maxPointsAmount: configuration.dailyRedemptionLimit,
        accumulatedPointsNumber: configuration.dailyEarnedLimit,
      });
    } catch (e: any) {
      setState({
        maxPointsAmount: '',
        accumulatedPointsNumber: '',
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const onSave = async () => {
    if (Number(state.maxPointsAmount) <= 0) {
      toast.error({ title: intl.formatMessage({ id: 'Maximum amount of points that can be redeemed is required' }) });
      return;
    }
    if (Number(state.accumulatedPointsNumber) <= 0) {
      toast.error({
        title: intl.formatMessage({ id: 'Number of points that can be accumulated per day is required' }),
      });
      return;
    }
    try {
      const body = {
        dailyEarnedLimit: state.accumulatedPointsNumber,
        dailyRedemptionLimit: state.maxPointsAmount,
      };

      await updateLoyalty(body).unwrap();
      toast.success({ title: intl.formatMessage({ id: 'Configuration is updated successfully' }) });
    } catch (error) {
      toast.error({ title: intl.formatMessage({ id: 'Updating configuration is failure' }) });
    }
  };

  return (
    <>
      <div className="configuration_wrapper">
        <div className="configuration_column">
          <p className="message_title">
            <FormattedMessage id={'Maximum amount of points that can be redeemed'} />
          </p>
          <Input
            className="order_prefix_input"
            value={state.maxPointsAmount}
            onChange={onChangeMaxPointsAmount}
            maxLength={6}
          />
        </div>
        <div className="configuration_column">
          <p className="message_title">
            <FormattedMessage id={'The number of points that can be accumulated per day'} />
          </p>
          <Input
            className="order_prefix_input"
            value={state.accumulatedPointsNumber}
            onChange={onChangeAccumulatedPointsNumber}
            maxLength={6}
          />
        </div>
      </div>
      <div className="buttons_wrapper">
        <Button className="save" disabled={false} onClick={onSave}>
          <FormattedMessage id={'Save'} />
          <ArrowForward className="icon" />
        </Button>
      </div>
    </>
  );
};

export default RewardsConfiguration;
