import { api } from '..';
import { LoyaltyConfiguration } from './types';

export const rewardsApi = api.enhanceEndpoints({ addTagTypes: ['Rewards'] }).injectEndpoints({
  endpoints: build => ({
    rewardSync: build.mutation<any, any>({
      query: body => ({
        url: '/v1/loyalty/rewards/sync',
        method: 'PUT',
        body,
      }),
    }),
    rewards: build.query<any, any>({
      query: () => ({
        url: `/v1/loyalty/rewards`,
        method: 'GET',
      }),
    }),
    rewardsPreview: build.query<any, any>({
      query: ({ queryString }) => ({
        url: `/v1/loyalty/rewards/preview?${queryString}`,
        method: 'GET',
      }),
    }),
    getOneReward: build.query<any, { id: number }>({
      query: ({ id }) => ({
        url: `/v1/loyalty/rewards/preview/${id}`,
        method: 'GET',
      }),
    }),
    updateReward: build.mutation<any, { data: any; id: number }>({
      query: ({ data, id }) => ({
        url: `/v1/loyalty/rewards/${id}`,
        method: 'PUT',
        body: data,
      }),
    }),
    //====================SUBTYPES==================
    getSubtypes: build.query<any, any>({
      query: () => ({
        url: 'v1/loyalty/subtabs/preview?sort[id]=asc',
        method: 'GET',
        cache: 'no-cache',
      }),
      transformResponse: (response: any) => {
        return response.subtabs.map((item: any, ind: number) => ({
          id: item.id,
          name: item.name,
          position: ind + 1,
        }));
      },
    }),
    createSubtabs: build.mutation<any, any>({
      query: body => ({
        url: `v1/loyalty/subtabs`,
        method: 'POST',
        body,
      }),
    }),
    updateSubtabs: build.mutation<any, { name: string; id: number }>({
      query: ({ name, id }) => ({
        url: `v1/loyalty/subtabs/${id}`,
        method: 'PUT',
        body: { name: name },
        cache: 'no-cache',
      }),
    }),
    deleteSubtabs: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `v1/loyalty/subtabs/${id}`,
        method: 'DELETE',
      }),
    }),
    updatePosition: build.mutation<void, { data: any }>({
      query: ({ data }) => ({
        url: 'v1/loyalty/subtabs/positions',
        method: 'PUT',
        cache: 'no-cache',
        body: { positions: data },
      }),
    }),
    //=================Campaings==============
    getLoyaltyCampaigns: build.query<any, any>({
      query: () => ({
        url: '/v1/loyalty/campaigns?sort[id]=desc',
        method: 'GET',
        cache: 'no-cache',
      }),
    }),
    //=================Configuration===========
    getLoyaltyConfiguration: build.query<LoyaltyConfiguration, any>({
      query: () => ({
        url: 'v1/loyalty/configuration',
        method: 'GET',
      }),
      transformResponse: (res: any) => {
        return {
          loyaltyEnabled: res.loyaltyEnabled,
          restaurantFlowEnabled: res.restaurantFlowEnabled,
          dailyEarnedLimit: res.dailyEarnedLimit,
          dailyRedemptionLimit: res.dailyRedemptionLimit,
        };
      },
    }),
    updateLoyaltyConfiguration: build.mutation<any, LoyaltyConfiguration>({
      query: body => ({
        url: 'v1/loyalty/configuration',
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const {
  useRewardSyncMutation,
  useLazyRewardsQuery,
  useLazyRewardsPreviewQuery,
  useLazyGetOneRewardQuery,
  useUpdateRewardMutation,
  useGetSubtypesQuery,
  useLazyGetSubtypesQuery,
  useCreateSubtabsMutation,
  useUpdateSubtabsMutation,
  useDeleteSubtabsMutation,
  useLazyGetLoyaltyCampaignsQuery,
  useLazyGetLoyaltyConfigurationQuery,
  useUpdateLoyaltyConfigurationMutation,
  useUpdatePositionMutation,
} = rewardsApi;
