import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { compose } from 'react-recompose';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
// import { Pagination } from 'antd';
import { getCurrency } from 'utilities/common';

const orderColors = ['yellow', 'pink', 'black', 'purple'];

function PlacedOrders({ intl, orderData, onSelectOrder }) {
  const deliveryTypes = {
    h: intl.formatMessage({ id: 'Home' }),
    o: intl.formatMessage({ id: 'Office' }),
    e: intl.formatMessage({ id: 'Other' }),
  };
  return (
    <div className="placed_orders_layout">
      <p className="header_title">
        <FormattedMessage id="Orders placed" />
      </p>
      <div className="table_content scrollbar">
        {orderData &&
          orderData.map((item, index) => (
            <div className={`table_item order_${item.state}`} key={index} onClick={() => onSelectOrder(item)}>
              <div className="bought">
                <p className="code">#{item.order || item.id}</p>
                <p className="note">
                  {item.paymentMethod.type === 'C'
                    ? 'Card'
                    : item.paymentMethod.type === 'S'
                    ? 'Cash'
                    : item.paymentMethod.type === 'CUIK'
                    ? 'CUIK'
                    : 'BTC'}
                </p>
                <div className="device">{item.channel || ''}</div>
              </div>
              <div className="date_time">
                <p className="time">{item.dueTo ? moment(item.dueTo).tz('America/Guatemala').format('LL') : ''}</p>
                <p className="note">
                  {item.dueTo
                    ? `Debe ser entregado a las ${moment(item.dueTo).tz('America/Guatemala').format('HH:mm')} horas`
                    : ''}
                </p>
                <p className="article">
                  {item.contents.length}artículos por {getCurrency().symbol}
                  {parseFloat(item.paymentAmount / 100).toFixed(2) || ''}
                </p>
              </div>
              <div className="delivery_address">
                <p className="address1">
                  {item.deliveryAddress.address || '-'}, {item.deliveryAddress.addressHouseNumber || '-'}
                </p>
                <p className="address2">{item.deliveryAddress.description || '-'}</p>
                <p className="product">{deliveryTypes[item.deliveryAddress.type.toLowerCase()] || ''}</p>
              </div>
            </div>
          ))}
      </div>
      {/* <div className="pagination_wrapper">
        <Pagination simple defaultCurrent={1} total={50} />
      </div> */}
    </div>
  );
}

PlacedOrders.defaultProps = {
  orderData: [],
};

export default compose(injectIntl)(PlacedOrders);
