import React from 'react';
import DealsConfiguration from './DealsConfiguration';
import RewardsConfiguration from './RewardsConfiguration';

type TabContentType = {
  index: number;
};

const TabContent: React.FC<TabContentType> = ({ index }) => {
  const activeTab = Number(index);
  return (
    <>
      {activeTab === 1 && <RewardsConfiguration />}
      {activeTab === 0 && <DealsConfiguration />}
    </>
  );
};

export default TabContent;
