import { ArrowDropDown } from '@material-ui/icons';
import { Input, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { setLoyaltyCampaign } from 'core/rewards/RewardsSlice';
import { useLazyGetLoyaltyCampaignsQuery } from 'core/rewards/RewarsService';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import dayjs, { Dayjs } from 'dayjs';

type SetupTypes = {
  productId: number;
  sarId: number;
  name: string;
  price: number;
  sku: number;
  image: string;
  weight: string;
  lastWeight: number;
  description: string;
  terms: string;
  campaign: number;
  isValid: boolean;
  setWeight: (e: any) => void;
  setDescription: (e: any) => void;
  setTerms: (e: any) => void;
  setCampaign: (e: any) => void;
};

const Setup: React.FC<SetupTypes> = ({
  productId,
  sarId,
  name,
  price,
  sku,
  image,
  weight,
  lastWeight,
  description,
  terms,
  campaign,
  isValid,
  setWeight,
  setDescription,
  setTerms,
  setCampaign,
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [getCampaignList] = useLazyGetLoyaltyCampaignsQuery();

  const { campaigns } = useAppSelector(state => state.rewardsReducer);

  const handleSort = (value: number) => {
    setCampaign(value);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = async () => {
    try {
      const loyaltyCampaings = await getCampaignList({}).unwrap();
      dispatch(setLoyaltyCampaign(loyaltyCampaings));
    } catch (error) {
      console.error('Error fetching campaign list:', error);
    } finally {
      /* setState({ ...state, isLoading: false }); */
    }
  };

  const onTimeChange = (value: Dayjs | null, dateString: any, callback: (value: Dayjs | null) => void) => {
    if (value) {
      callback(dayjs(value).set('s', 0).set('ms', 0));
    } else {
      callback(null);
    }
  };

  const onChange = (e: any) => {
    const value = e.target.value.trimStart();
    const numericValue = value.replace(/[^\d]/g, '');
    setWeight(numericValue === '' ? '' : numericValue);
  };

  const checkField = (value: any, isWeight?: boolean) => {
    if ((Number(value) === 0 || Number(value) >= 2147483648) && isWeight) {
      return true;
    }
    if (isValid) return;
    if (value) {
      const valid = value.length > 0 || value > 0;
      return !valid;
    } else {
      return true;
    }
  };
  return (
    <>
      <div className="">
        <div className="basic_info_form">
          <div className="setup_input">
            <>
              <div className="input_group">
                <div className="input_item">
                  <p className="title_deals">
                    <FormattedMessage id="Product ID" />
                  </p>
                  <Input
                    placeholder=""
                    value={productId}
                    maxLength={200}
                    min={0}
                    className={'reward_data'}
                    disabled={true}
                  />
                </div>
                <div className="input_item">
                  <p className="title_deals">
                    <FormattedMessage id="SAR ID" />
                  </p>
                  <Input
                    placeholder=""
                    value={sarId}
                    maxLength={200}
                    min={0}
                    className={'reward_data'}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="line_divider" />
              <div className="input_group">
                <div className="input_item">
                  <p className="title_deals">
                    <FormattedMessage id="Name" />
                  </p>
                  <Input
                    placeholder=""
                    value={name}
                    maxLength={200}
                    min={0}
                    className={'reward_data'}
                    disabled={true}
                  />
                </div>
                <div className="input_item">
                  <p className="title_deals">
                    <FormattedMessage id="Price" />
                  </p>
                  <Input
                    placeholder=""
                    value={price}
                    maxLength={200}
                    min={0}
                    className={'reward_data'}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="line_divider" />
              <div className="input_group">
                <div className="input_item">
                  <p className="title_deals">
                    <FormattedMessage id="SKU" />
                  </p>
                  <Input placeholder="" value={sku} maxLength={200} min={0} className={'reward_data'} disabled={true} />
                </div>
                <div className="input_item">
                  <p className="title_deals">
                    <FormattedMessage id="Weight" />
                  </p>
                  <Input
                    placeholder=""
                    value={weight}
                    onChange={e => onChange(e)}
                    maxLength={200}
                    min={0}
                    className={`reward_data weight ${checkField(weight, true) ? 'has-error' : ''}`}
                  />
                  <span className="last_weight">
                    {intl.formatMessage({ id: 'Last weight used' })} {lastWeight}
                  </span>
                  {checkField(weight, true) && (
                    <span className="error_msg">
                      <FormattedMessage id="Field is required" />
                    </span>
                  )}
                </div>
              </div>
              <div className="line_divider" />
              <p className="title_deals">
                <FormattedMessage id="Image" />
              </p>
              <div className="image_container">
                <img src={image} alt="#" />
              </div>
              <div className="line_divider" style={{ marginTop: '20px' }} />
              <p className="title_deals">
                <FormattedMessage id="Associated Campaign" />
              </p>
              <Select
                className={`select_modal ${checkField(campaign) ? 'has-error' : ''}`}
                suffixIcon={<ArrowDropDown />}
                onChange={handleSort}
                id="campaign_status_filter"
                value={campaign}
                dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
              >
                {campaigns &&
                  campaigns?.campaigns &&
                  campaigns?.campaigns.map((item: any) => (
                    <Select.Option value={item.id} key={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
              </Select>
              {checkField(campaign) && (
                <span className="error_msg">
                  <FormattedMessage id="Field is required" />
                </span>
              )}
              <div className="line_divider" style={{ marginTop: '20px' }} />
              <p className="title_deals">
                <FormattedMessage id="Description" />
              </p>
              <div>
                <TextArea
                  rows={4}
                  placeholder=""
                  value={description}
                  onChange={e => setDescription(e.target.value.trimStart())}
                  maxLength={500}
                  style={{
                    marginBottom: !checkField(description) ? '20px' : 0,
                    resize: 'none',
                    backgroundColor: '#F4F5F7',
                    border: !checkField(description) ? 'none' : '',
                  }}
                  className={checkField(description) ? 'has-error_area' : ''}
                />
                {checkField(description) && (
                  <>
                    <span className="error_msg">
                      <FormattedMessage id="Field is required" />
                    </span>
                    <div style={{ marginBottom: '20px' }} />
                  </>
                )}
              </div>
              <p className="title_deals">
                <FormattedMessage id="Terms" />
              </p>
              <div>
                <TextArea
                  rows={4}
                  placeholder=""
                  value={terms}
                  onChange={e => setTerms(e.target.value.trimStart())}
                  maxLength={4000}
                  style={{
                    marginBottom: !checkField(terms) ? '20px' : 0,
                    resize: 'none',
                    backgroundColor: '#F4F5F7',
                    border: !checkField(terms) ? 'none' : '',
                  }}
                  className={checkField(terms) ? 'has-error_area' : ''}
                />
                {checkField(terms) && (
                  <span className="error_msg">
                    <FormattedMessage id="Field is required" />
                  </span>
                )}
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setup;
