import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RewardsPreviewType, initState } from './types';

const initialState: initState = {
  rewardsPreview: {
    rewards: [],
    count: null,
    minValue: 0,
    maxValue: 0,
  },
  totalRewardsCount: 0,
  rewardFulldData: {},
  campaigns: [],
};

const rewardsSlice = createSlice({
  name: 'rewards',
  initialState,
  reducers: {
    setRewardsPreview(state: any, action: PayloadAction<RewardsPreviewType>) {
      state.rewardsPreview = action.payload;
    },
    setTotalRewardsCount(state: any, action: PayloadAction<number>) {
      state.totalRewardsCount = action.payload;
    },
    setRewardData(state: any, action: PayloadAction<any>) {
      state.rewardFulldData = action.payload;
    },
    setLoyaltyCampaign(state: any, action: PayloadAction<any>) {
      state.campaigns = action.payload;
    },
  },
});

export const { setRewardsPreview, setRewardData, setLoyaltyCampaign, setTotalRewardsCount } = rewardsSlice.actions;

export default rewardsSlice.reducer;
