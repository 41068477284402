import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Tooltip } from 'antd';
import ModalDlg from 'components/Basic/ModalDlg';
import { Close, Edit, Delete, ArrowDownwardOutlined, ArrowUpwardOutlined } from '@material-ui/icons';
import { ArrowForward } from '@material-ui/icons';
import toast from 'components/Basic/Toast';
import Loading from 'components/Basic/Loading';
import SubtypeEditModal from './SubtypeEditModal';
import { useGetSubtypesQuery, useDeleteSubtabsMutation, useUpdatePositionMutation } from 'core/rewards/RewarsService';

type TabsInfo = {
  id: number;
  name: string;
};

type SybtypeModalType = {
  isOpenModal: boolean;
  onCloseModal: () => void;
};

const SubtypeModal: React.FC<SybtypeModalType> = ({ isOpenModal, onCloseModal }) => {
  const [tab, setTab] = useState<TabsInfo | null>(null);
  const [isModal, setModal] = useState<boolean>(false);
  const intl = useIntl();
  const {
    data: subtypes,
    isLoading: subtypesLoading,
    isFetching: subtypesFetching,
    refetch,
  } = useGetSubtypesQuery({}, { refetchOnFocus: true });
  const [deleteSubtypes, { isLoading: deleteLoading }] = useDeleteSubtabsMutation();
  const [updatePosition, { isLoading: positionLoading }] = useUpdatePositionMutation();

  const handleDelete = async (id: number) => {
    try {
      await deleteSubtypes({ id: id }).unwrap();
      refetch();
      toast.success({
        title: intl.formatMessage({
          id: 'Subtype is deleted successfully!',
        }),
      });
    } catch (error) {
      toast.error({
        title: intl.formatMessage({
          id: 'Subtype is deleted failure!',
        }),
      });
    }
  };
  const handleOrderChange = async (items: Array<any>) => {
    try {
      await updatePosition({ data: items }).unwrap();
      refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeButtonClick = (currentPosition: number, currentIndex: number, direction: 'up' | 'down') => {
    const newIndex = currentIndex + (direction === 'up' ? -1 : 1);

    if (newIndex < 0 || newIndex >= subtypes?.length) return;

    const updatedArray = subtypes.map((item: any, index: number) => {
      if (index === currentIndex) return { ...item, position: subtypes[newIndex].position };
      if (index === newIndex) return { ...item, position: currentPosition };
      return item;
    });

    handleOrderChange(updatedArray);
  };

  return (
    <ModalDlg className="suptype_view_modal" visible={isOpenModal} width={580} handleCancel={onCloseModal} isCenter>
      <>
        <div className="modal_header">
          <div className="name_wrapper">
            <h3>
              <FormattedMessage id={'Reward subtypes'} />
            </h3>
            <div className="close_modal" onClick={onCloseModal}>
              <Close />
            </div>
          </div>
        </div>
        <div className="new_subtype_form_wrapper">
          <div className="tabs_input">
            <div className="add_wrapper">
              <Button
                className="add_btn"
                onClick={() => {
                  setTab(null);
                  setModal(true);
                }}
              >
                <FormattedMessage id="Add new subtype" />
                <ArrowForward />
              </Button>
            </div>
          </div>
          <div className="table_header">
            <div className="id">
              <FormattedMessage id="#" />
            </div>
            <div className="name">
              <FormattedMessage id="Subtype name" />
            </div>
            <div className="actions">
              <FormattedMessage id="Actions" />
            </div>
          </div>
          <div className="table">
            {subtypes?.map((item: any, ind: number) => {
              return (
                <>
                  <div key={ind} className="table_group">
                    <div className="id">
                      <p>{item.id}</p>
                    </div>
                    <div className="name">
                      <p>{item.name}</p>
                    </div>
                    <div className="actions">
                      <Tooltip placement="top" title={intl.formatMessage({ id: 'Edit subtype' })}>
                        <Button
                          className="edit_btn"
                          onClick={() => {
                            setTab(item);
                            setModal(true);
                          }}
                        >
                          <Edit />
                        </Button>
                      </Tooltip>
                      <Tooltip placement="top" title={intl.formatMessage({ id: 'Delete subtype' })}>
                        <Button
                          className="edit_btn"
                          onClick={() => {
                            handleDelete(item.id);
                          }}
                        >
                          <Delete />
                        </Button>
                      </Tooltip>
                      <Tooltip placement="top" title={intl.formatMessage({ id: 'Down tab' })}>
                        <Button
                          className="edit_btn"
                          onClick={() => handleChangeButtonClick(item.position, ind, 'down')}
                          style={ind !== subtypes?.length - 1 ? {} : { pointerEvents: 'none', opacity: 0.25 }}
                        >
                          <ArrowDownwardOutlined />
                        </Button>
                      </Tooltip>
                      <Tooltip placement="top" title={intl.formatMessage({ id: 'Up tab' })}>
                        <Button
                          className="edit_btn"
                          onClick={() => handleChangeButtonClick(item.position, ind, 'up')}
                          style={ind !== 0 ? {} : { pointerEvents: 'none', opacity: 0.25 }}
                        >
                          <ArrowUpwardOutlined />
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <Loading visible={subtypesLoading || deleteLoading || positionLoading || subtypesFetching} />
        <SubtypeEditModal
          isOpenModal={isModal}
          onCloseModal={() => {
            setModal(false);
          }}
          refetch={() => refetch()}
          tabsInfo={tab}
        />
      </>
    </ModalDlg>
  );
};

export default SubtypeModal;
