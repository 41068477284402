import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'antd';
import { ArrowDownwardOutlined, ArrowUpwardOutlined } from '@material-ui/icons';

type Inner = {
  id: string;
  text: string;
};

type FiltersType = {
  lowerList: Array<Inner>;
  setLowerList: (e: any) => void;
  upperList: Array<Inner>;
  setUpperList: (e: any) => void;
  isValid: boolean;
};

const Filters: React.FC<FiltersType> = ({ lowerList, setLowerList, upperList, setUpperList, isValid }) => {
  const intl = useIntl();

  const [selectedItem, setSelectedItem] = useState<any>(null);

  const handleSelect = (item: any) => {
    setSelectedItem(item);
  };

  const handleAdd = () => {
    if (selectedItem && !lowerList.some((item: any) => item.id === selectedItem.id)) {
      setLowerList([...lowerList, selectedItem]);
      setUpperList(upperList.filter((item: any) => item.id !== selectedItem.id));
      setSelectedItem(null);
    }
  };

  const handleRemove = () => {
    if (selectedItem && !upperList.some((item: any) => item.id === selectedItem.id)) {
      setUpperList([...upperList, selectedItem]);
      setLowerList(lowerList.filter((item: any) => item.id !== selectedItem.id));
      setSelectedItem(null);
    }
  };

  const listStyleUpper = {
    height: '96px',
    overflowY: 'auto',
    border: '1px solid #CFCFCF',
    padding: '0px',
    margin: '8px 0',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
  } as React.CSSProperties;

  const listStyleLower = {
    height: '96px',
    overflowY: 'auto',
    border: '1px solid #CFCFCF',
    padding: '0px',
    margin: '8px 0',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
  } as React.CSSProperties;

  const checkField = (value: any, isArray: boolean) => {
    if (isValid) return;
    if (isArray) {
      return value.length === 0;
    }
    if (value && !isArray) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="new_filters_form_wrapper" id="" style={{ marginBottom: '20px' }}>
      <div className="basic_info_form">
        <div>
          <p className="title_deals">Etiqueta de contenido</p>
          <div style={listStyleUpper}>
            {upperList?.map((item: any) => (
              <div
                key={item.id}
                style={{
                  padding: '4px',
                  cursor: 'pointer',
                  fontSize: '12px',
                  fontWeight: '700',
                  lineHeight: 'normal',
                  backgroundColor: selectedItem && selectedItem.id === item.id ? '#EDF0F2' : 'transparent',
                }}
                onClick={() => handleSelect(item)}
              >
                <FormattedMessage id={item.text} />
              </div>
            ))}
          </div>
          <div className="content-tab-btn">
            <Button onClick={handleAdd} style={{ marginRight: '10px' }}>
              <span>
                <FormattedMessage id={'Add'} />
              </span>
              <ArrowDownwardOutlined />
            </Button>
            {lowerList?.length !== 0 && (
              <Button onClick={handleRemove}>
                <span>
                  <FormattedMessage id={'Remove'} />
                </span>
                <ArrowUpwardOutlined />
              </Button>
            )}
            {checkField(lowerList, true) && (
              <span style={{ marginLeft: 12 }} className="error_msg">
                <FormattedMessage id="Add some options" />
              </span>
            )}
          </div>
          <div style={listStyleLower}>
            {lowerList?.map((item: any) => (
              <div
                key={item.id}
                style={{
                  padding: '4px',
                  cursor: 'pointer',
                  fontSize: '12px',
                  fontWeight: '700',
                  lineHeight: 'normal',
                  backgroundColor: selectedItem && selectedItem.id === item.id ? '#EDF0F2' : 'transparent',
                }}
                onClick={() => handleSelect(item)}
              >
                <FormattedMessage id={item.text} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;
