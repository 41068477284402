import { Button } from 'antd';
import Loading from 'components/Basic/Loading';
import toast from 'components/Basic/Toast';
import RewardsFilters from 'components/Dashboard/Rewards/RewardsFilters';
import RewardsTable from 'components/Dashboard/Rewards/RewardsTable';
import { setLoyaltyCampaign, setRewardsPreview, setTotalRewardsCount } from 'core/rewards/RewardsSlice';
import {
  useLazyGetLoyaltyCampaignsQuery,
  useLazyRewardsPreviewQuery,
  useRewardSyncMutation,
} from 'core/rewards/RewarsService';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import { CategoryOutlined } from '@material-ui/icons';
import SubtypeModal from 'components/Dashboard/Rewards/SubtypeModal';

const Rewards: React.FC = () => {
  const intl = useIntl();
  const [state, setState] = useState<any>({
    isLoading: false,
    searchText: '',
    sortStatus: '',
    filterStatus: null,
    filterTab: null,
    filterCat: null,
    filterCompaign: null,
    filterFrom: null,
    filterTo: null,
    sortName: '',
    sortId: 'desc',
    startDate: '',
    endDate: '',
    sortWeight: '',
    sortDate: '',
  });

  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [syncRewards, { isLoading: syncLoading }] = useRewardSyncMutation();
  const [getRewards] = useLazyRewardsPreviewQuery();
  const [isPointValid, setPointValid] = useState<boolean>(true);
  const [isShowListModal, setShowListModal] = useState<boolean>(false);

  const statuses = [
    { value: '', label: intl.formatMessage({ id: 'All' }) },
    { value: 'true', label: intl.formatMessage({ id: 'Active' }) },
    { value: 'false', label: intl.formatMessage({ id: 'Inactive' }) },
  ];

  const sorts = [
    { value: 'Idesc', label: intl.formatMessage({ id: 'ID (desc)' }) },
    { value: 'Iasc', label: intl.formatMessage({ id: 'ID (asc)' }) },
    { value: 'Nasc', label: intl.formatMessage({ id: 'Name (A-Z)' }) },
    { value: 'Ndesc', label: intl.formatMessage({ id: 'Name (Z-A)' }) },
    { value: 'Sdesc', label: intl.formatMessage({ id: 'Status (Active first)' }) },
    { value: 'Sasc', label: intl.formatMessage({ id: 'Status (Inactive first)' }) },
    // { value: 'Wdesc', label: intl.formatMessage({ id: 'Weight (desc)' }) },
    // { value: 'Wasc', label: intl.formatMessage({ id: 'Weight (asc)' }) },
  ];

  const dispatch = useAppDispatch();

  const [getCampaignList] = useLazyGetLoyaltyCampaignsQuery();

  const [campaigns, setCampaings] = useState<any>([]);

  const { rewardsPreview, totalRewardsCount } = useAppSelector(state => state.rewardsReducer);

  const { rewardId } = useParams<{ rewardId: string }>();

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = async () => {
    setState({ ...state, isLoading: true });
    const queryString = buildQueryString({});
    try {
      const rewards = await getRewards({ queryString }).unwrap();
      dispatch(setRewardsPreview(rewards));
      dispatch(setTotalRewardsCount(rewards.count));
      const loyaltyCampaings = await getCampaignList({}).unwrap();
      dispatch(setLoyaltyCampaign(loyaltyCampaings));
      setCampaings(loyaltyCampaings);
    } catch (error: any) {
      toast.error({ title: error.message });
    } finally {
      setState({ ...state, isLoading: false });
    }
  };

  const handleChangePage = async (page: number, size: number) => {
    setState({ ...state, isLoading: true });
    setPageSize(size);
    try {
      const queryString = buildQueryString({
        search: state.searchText.toLowerCase(),
        page,
        size,
        count: true,
        sort_by_status: state.sortStatus,
        sort_by_id: state.sortId,
        sort_by_name: state.sortName,
        filter: state.filterStatus,
        filter_tab: state.filterTab,
        filter_cat: state.filterCat,
        filter_campaign: getFilterCompaignString(),
        filter_from: state.filterFrom,
        filter_to: state.filterTo,
        start_date: state.startDate,
        end_date: state.endDate,
        sort_by_weight: state.sortWeight,
        sort_by_date: state.sortDate,
      });

      const res = await getRewards({ queryString }).unwrap();
      dispatch(setRewardsPreview(res));
    } catch (e) {
      console.log(e);
    } finally {
      setState({ ...state, isLoading: false });
    }
  };

  const handleSearch = async (e: any) => {
    const searchTextValue = e.target.value;
    setState({ ...state, searchText: searchTextValue });
    if (e.keyCode === 13) {
      if (!isPointValid) {
        toast.error({ title: intl.formatMessage({ id: 'Invalid Points Filter' }) });
        return;
      }
      setCurrent(1);
      try {
        const queryString = buildQueryString({
          search: e.target.value.trim().toLowerCase(),
          page: 1,
          size: pageSize,
          count: true,
          sort_by_status: state.sortStatus,
          sort_by_id: state.sortId,
          sort_by_name: state.sortName,
          filter: state.filterStatus,
          filter_tab: state.filterTab,
          filter_cat: state.filterCat,
          filter_campaign: getFilterCompaignString(),
          filter_from: state.filterFrom,
          filter_to: state.filterTo,
          start_date: state.startDate,
          end_date: state.endDate,
          sort_by_weight: state.sortWeight,
          sort_by_date: state.sortDate,
        });

        const res = await getRewards({ queryString }).unwrap();

        dispatch(setRewardsPreview(res));
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleStatusFilter = (value: string) => {
    setState({ ...state, filterStatus: value });
  };

  const onCampaignFilter = (value: string, option: any) => {
    setState({ ...state, filterCompaign: { id: option.key, name: option.value } });
  };

  const onFromFilter = (value: string) => {
    setState({ ...state, filterFrom: value });
  };

  const onToFilter = (value: string) => {
    setState({ ...state, filterTo: value });
  };

  const onTabFilter = (value: string) => {
    setState({ ...state, filterTab: value });
  };

  const onCatFilter = (value: string) => {
    setState({ ...state, filterCat: value });
  };

  const onStartFilterChange = (value: string) => {
    setState({ ...state, startDate: value });
  };

  const onEndFilterChange = (value: string) => {
    setState({ ...state, endDate: value });
  };

  const onPointFilterValid = (value: boolean) => {
    setPointValid(value);
  };

  const handleSort = (value: string) => {
    let newState = { ...state };
    if (value[0] === 'I') {
      newState = {
        ...newState,
        sortId: value.substring(1),
        sortName: '',
        sortStatus: '',
        sortWeight: '',
        sortDate: '',
      };
    } else if (value[0] === 'N') {
      newState = {
        ...newState,
        sortName: value.substring(1),
        sortId: '',
        sortStatus: '',
        sortWeight: '',
        sortDate: '',
      };
    } else if (value[0] === 'S') {
      newState = {
        ...newState,
        sortStatus: value.substring(1),
        sortId: '',
        sortName: '',
        sortWeight: '',
        sortDate: '',
      };
    } else if (value[0] === 'W') {
      newState = {
        ...newState,
        sortWeight: value.substring(1),
        sortId: '',
        sortName: '',
        sortStatus: '',
        sortDate: '',
      };
    } else if (value[0] === 'D') {
      newState = {
        ...newState,
        sortDate: value.substring(1),
        sortId: '',
        sortName: '',
        sortStatus: '',
        sortWeight: '',
      };
    }
    setState(newState);
  };

  const getFilterCompaignString = () => {
    const compainId = state.filterCompaign ? state.filterCompaign.id : '';
    if (compainId === 'allCategories') {
      return '';
    }
    return compainId;
  };

  const onApplyFilter = async () => {
    if (!isPointValid) {
      toast.error({ title: intl.formatMessage({ id: 'Invalid Points Filter' }) });
      return;
    }
    setState({ ...state, isLoading: true });
    setCurrent(1);

    try {
      const queryString = buildQueryString({
        search: state.searchText.trim().toLowerCase(),
        page: 1,
        size: pageSize,
        count: true,
        sort_by_status: state.sortStatus,
        sort_by_id: state.sortId,
        sort_by_name: state.sortName,
        filter: state.filterStatus,
        filter_tab: state.filterTab,
        filter_cat: state.filterCat,
        filter_campaign: getFilterCompaignString(),
        filter_from: state.filterFrom,
        filter_to: state.filterTo,
        start_date: state.startDate,
        end_date: state.endDate,
        sort_by_weight: state.sortWeight,
        sort_by_date: state.sortDate,
      });

      const res = await getRewards({ queryString }).unwrap();

      dispatch(setRewardsPreview(res));
    } catch (e) {
      console.log(e);
    } finally {
      setState({ ...state, isLoading: false });
    }
  };

  const onHandleSyncRewards = async () => {
    try {
      await syncRewards({}).unwrap();
      setState({ ...state, isLoading: true });
      const queryString = buildQueryString({});

      try {
        const rewards = await getRewards({ queryString }).unwrap();
        dispatch(setRewardsPreview(rewards));
        dispatch(setTotalRewardsCount(rewards.count));
      } catch (error: any) {
        toast.error({ title: error.message });
      } finally {
        setState({ ...state, isLoading: false });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="rewards_wrapper">
      <div className="btn_wrapper">
        <div className="sync_container">
          <div className="info_wrapper">
            <p className="reward_count">{totalRewardsCount}</p>
            <p className="count_desc">
              <FormattedMessage id="Rewards downloaded" />
            </p>
          </div>
          <Button className="sync_btn" onClick={onHandleSyncRewards}>
            <FormattedMessage id="Sync" />
          </Button>
        </div>
        <div className="subtype_btn">
          <Button className="add_btn" onClick={() => setShowListModal(true)}>
            <FormattedMessage id="Configure subtypes" />
            <CategoryOutlined />
          </Button>
        </div>
      </div>
      {rewardsPreview && rewardsPreview.rewards && (
        <RewardsFilters
          sorts={sorts}
          statuses={statuses}
          campaigns={campaigns?.campaigns}
          searchText={state.searchText}
          selectedStatus={state.filterStatus}
          selectedTab={state.filterTab}
          selectedCategory={state.filterCat}
          selectedCompaign={state.filterCompaign}
          selectedFrom={state.filterFrom}
          selectedTo={state.filterTo}
          selectedSortStatus={state.sortStatus}
          points={{ min: rewardsPreview.minValue, max: rewardsPreview.maxValue }}
          onSelectSort={handleSort}
          onSelectStatus={handleStatusFilter}
          onSelectCategory={onCatFilter}
          onSelectTab={onTabFilter}
          onSelectCampaign={onCampaignFilter}
          onSelectFrom={onFromFilter}
          onSelectTo={onToFilter}
          onChangeStartDate={onStartFilterChange}
          onChangeEndDate={onEndFilterChange}
          onChangeSearch={handleSearch}
          onApplyClick={onApplyFilter}
          onPointFilterValid={onPointFilterValid}
        />
      )}
      <RewardsTable
        current={current}
        rewardId={rewardId}
        total={rewardsPreview && rewardsPreview.count ? rewardsPreview.count : 0}
        rewardData={rewardsPreview && rewardsPreview.rewards ? rewardsPreview.rewards : null}
        setCurrent={setCurrent}
        onChangePage={(current, size) => handleChangePage(current, size)}
      />
      {(state.isLoading || syncLoading) && <Loading visible={state.isLoading || syncLoading} />}
      <SubtypeModal isOpenModal={isShowListModal} onCloseModal={() => setShowListModal(false)} />
    </div>
  );
};

Rewards.defaultProps = {
  list: {},
};

export default Rewards;

const buildQueryString = ({
  search = '',
  page = 1,
  size = 10,
  count = true,
  sort_by_status = '',
  sort_by_id = 'desc',
  sort_by_name = '',
  filter = '',
  filter_tab = '',
  filter_cat = '',
  filter_campaign = '',
  filter_from = '',
  filter_to = '',
  start_date = '',
  end_date = '',
  sort_by_date = '',
  sort_by_weight = '',
}) => {
  const offset = size && page && (page - 1) * size;

  const params = [`match=${encodeURIComponent(search)}`, `offset=${offset}`, `limit=${size}`, `count=${count}`];

  if (sort_by_status) {
    params.push(`sort[isActive]=${sort_by_status}`);
  }

  if (sort_by_id) {
    params.push(`sort[id]=${sort_by_id}`);
  }

  if (sort_by_name) {
    params.push(`sort[name]=${sort_by_name}`);
  }

  if (sort_by_date) {
    params.push(`sort[date]=${sort_by_date}`);
  }

  if (filter) {
    params.push(`filter[isActive]=${filter}`);
  }

  if (filter_tab) {
    params.push(`filter[tab]=${filter_tab}`);
  }

  if (filter_cat) {
    params.push(`filter[category]=${filter_cat}`);
  }

  if (filter_campaign) {
    params.push(`filter[campaignId]=${filter_campaign}`);
  }

  if (filter_from) {
    params.push(`filter[points][gte]=${filter_from}`);
  }

  if (filter_to) {
    params.push(`filter[points][lte]=${filter_to}`);
  }

  if (start_date) {
    params.push(`filter[startDate]=${start_date}`);
  }

  if (end_date) {
    params.push(`filter[endDate]=${end_date}`);
  }

  if (sort_by_weight) {
    params.push(`sort[weight]=${sort_by_weight}`);
  }

  return params.join('&');
};
