import { api } from '..';
import { OrderData } from './types';

export const orderApi = api.enhanceEndpoints({ addTagTypes: ['Order'] }).injectEndpoints({
  endpoints: build => ({
    getOrderList: build.query<
      OrderData,
      {
        offset: number;
        limit: number;
        or: boolean;
        filter: string;
        sort: string;
        distribution_received: string;
        url: string;
        searchItem: any;
      }
    >({
      query: ({ offset, limit, or, filter, sort, distribution_received, url, searchItem }) => ({
        url:
          url.length > 0 && searchItem
            ? `${url}${searchItem}/?size=${limit || 10}&page=${offset / limit + 1 || 1}${
                sort && sort.length > 0 ? `${sort}` : '&sort=id'
              }${filter}`
            : `${url}?size=${limit || 10}&page=${offset / limit + 1 || 1}${
                sort && sort.length > 0 ? `${sort}` : '&sort=id'
              }${filter}`,
        method: 'GET',
      }),
    }),
    orderProceed: build.mutation<any, { id: number }>({
      query: ({ id }) => ({
        url: `v0/orders/${id}/proceed?bypass=A`,
        method: 'PUT',
      }),
    }),
    cancelOrder: build.mutation<any, { id: number }>({
      query: ({ id }) => ({
        url: `v0/orders/${id}/cancel`,
        method: 'PUT',
      }),
    }),
    getCustomerOrders: build.query<
      OrderData,
      {
        id: number;
      }
    >({
      query: ({ id }) => ({
        url: `v1/admin-area/orders/customers/${id}/?size=10&page=1&sort=id`,
      }),
    }),
    getDistributionOrders: build.query<
      OrderData,
      {
        offset: number;
        limit: number;
        or: boolean;
        filter: string;
        sort: string;
        distribution_received: string;
        url: string;
        searchItem: any;
      }
    >({
      query: ({ offset, limit, or, filter, sort, distribution_received, url, searchItem }) => ({
        url: `v0/orders?details=base&offset=${offset || 0}&limit=${limit || 5}${
          distribution_received ? `&distribution_received=true` : ''
        }${or ? `&or=${or}` : ''}${filter || ''}${sort ? `&sort=${sort}` : '&sort=id desc'}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLazyGetOrderListQuery,
  useOrderProceedMutation,
  useCancelOrderMutation,
  useLazyGetCustomerOrdersQuery,
  useLazyGetDistributionOrdersQuery,
} = orderApi;
