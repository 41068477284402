import React, { useState } from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Button } from 'antd';
import ModalDlg from 'components/Basic/ModalDlg';
import { Close } from '@material-ui/icons';
import { Edit } from '@material-ui/icons';
import { OffersFullData } from 'core/offers/types';
import { NavLink } from 'react-router-dom';

type CodeViewModalType = {
  isOpenModal: boolean;
  onCloseModal: () => void;
  data: OffersFullData;
  openOfferModal: () => void;
};

const CodeViewModal: React.FC<CodeViewModalType> = ({ isOpenModal, onCloseModal, data, openOfferModal }) => {
  const intl = useIntl();
  const setFontSize = () => {
    const str = data?.hiddenCode?.length;
    return str < 18 && str > 12 ? 20 : str <= 12 && str > 8 ? 28 : 36;
  };

  return (
    <>
      <ModalDlg className="code_view_modal" visible={isOpenModal} width={574} handleCancel={onCloseModal} isCenter>
        <>
          <div className="modal_header">
            <div className="name_wrapper">
              <div className="name_info">
                <h3>{data?.name}</h3>
                <p>ID {data?.id}</p>
              </div>
              <span className="code_subtitle">
                {intl.formatMessage({ id: 'See the code and QR Code of the offer' })}
              </span>

              <div className="close_modal" onClick={onCloseModal}>
                <Close />
              </div>
            </div>
          </div>
          <div className="modal_content">
            {data?.isSecret ? (
              <>
                <div className="modal_content_text">
                  <h2 style={{ fontSize: setFontSize() }}>{data?.hiddenCode}</h2>
                  <p>{intl.formatMessage({ id: 'Text code' })}</p>
                </div>
                <div className="modal_content_qrcode">
                  <img src={data?.qrCode} alt="QR Code" width={90} height={90} />
                  <span className="qr_code_text">{data?.hiddenCode}</span>
                  <p>
                    <FormattedMessage id="QR Code" />
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className="modal_text_code">
                  <h2>{data?.hiddenCode}</h2>
                  <p>{intl.formatMessage({ id: 'Text code' })}</p>
                </div>
              </>
            )}
          </div>
          <div className="open_offer">
            <NavLink to={`/dashboard/deals/offers/${data.id}`}>
              <Button
                className="edit_btn"
                onClick={() => {
                  openOfferModal();
                  onCloseModal();
                }}
              >
                <Edit />
              </Button>
            </NavLink>
          </div>
        </>
      </ModalDlg>
    </>
  );
};

export default CodeViewModal;
