import React, { useEffect } from 'react';
import { Button } from 'antd';
import { ArrowBack } from '@material-ui/icons';
import data from '../../../package.json';
import { compose } from 'react-recompose';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import { setVersionData } from 'core/version/VersionSlice';
import { useLazyGetVersionQuery, useGetLoyaltyVersionQuery, useGetNp6VersionQuery } from 'core/version/VersionService';

const Version = ({ history }) => {
  const currentVersionApp = data.version;

  const { version: versionBack } = useAppSelector(state => state.versionReducer);
  const { data: loyalty } = useGetLoyaltyVersionQuery({}, { refetchOnFocus: true });
  const { data: np6 } = useGetNp6VersionQuery({}, { refetchOnFocus: true });

  const dispatch = useAppDispatch();
  const [getVersion] = useLazyGetVersionQuery();

  const handleGoBack = () => {
    history.goBack();
  };

  useEffect(() => {
    const version = async () => {
      try {
        const res = await getVersion({}).unwrap();
        dispatch(setVersionData({ version: res.version }));
      } catch (e) {
        if (e.status === 404) {
          dispatch(setVersionData({ version: 'You need to login to see the current version' }));
        }
      }
    };
    version();
  }, []);

  return (
    <div className="version_page_wrapper">
      <div>
        <p className="title">Version frontend: {currentVersionApp}</p>
        <p className="title">Main service version: {versionBack}</p>
        <p className="title">Loyalty service version: {loyalty}</p>
        <p className="title">NP6 gateway version: {np6}</p>
        <Button onClick={handleGoBack} className="button" type="button">
          <ArrowBack />
          <span>Go back</span>
        </Button>
      </div>
    </div>
  );
};

Version.defaultProps = {
  versionBack: '',
};

export default compose(injectIntl, withRouter)(Version);
