import React, { useState } from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Button, Tabs } from 'antd';
import ModalDlg from 'components/Basic/ModalDlg';
import { Close } from '@material-ui/icons';
import { Edit } from '@material-ui/icons';
import { OffersFullData } from 'core/offers/types';
import { NavLink } from 'react-router-dom';

type CodeViewModalType = {
  isOpenModal: boolean;
  onCloseModal: () => void;
  data: OffersFullData;
  openOfferModal: () => void;
};

const CodeViewModal: React.FC<CodeViewModalType> = ({ isOpenModal, onCloseModal, data, openOfferModal }) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const intl = useIntl();
  const setFontSize = () => {
    const str = data?.hiddenCode?.length;
    return str < 18 && str > 12 ? 20 : str <= 12 && str > 8 ? 28 : 36;
  };

  const tabNames = [<>{intl.formatMessage({ id: 'Code' })}</>, <>{intl.formatMessage({ id: 'Preview' })}</>];

  const callbackTabClicked = (key: any) => {
    setActiveTab(Number(key));
  };

  return (
    <>
      <ModalDlg
        className="reward_code_view_modal"
        visible={isOpenModal}
        width={574}
        handleCancel={onCloseModal}
        isCenter
      >
        <>
          <div className="modal_header">
            <div className="name_wrapper">
              <div className="name_info">
                <h3>{data?.name}</h3>
                <p>ID {data?.id}</p>
              </div>

              <span className="code_subtitle">{intl.formatMessage({ id: 'See the code of the reward' })}</span>

              <div className="close_modal" onClick={onCloseModal}>
                <Close />
              </div>
            </div>
          </div>
          {activeTab === 1 ? (
            <>
              <div className="modal_content">
                <>
                  <div className="modal_content_text">
                    <h2 style={{ fontSize: setFontSize() }}>{data?.hiddenCode}</h2>
                    <p>{intl.formatMessage({ id: 'Text code' })}</p>
                  </div>
                  <div className="modal_content_qrcode">
                    <img src={data?.qrCode} alt="QR Code" width={90} height={90} />
                    <span className="qr_code_text">{data?.hiddenCode} </span>
                    <p>
                      <FormattedMessage id="QR Code" />
                    </p>
                  </div>
                </>
              </div>
            </>
          ) : (
            <div className="modal_content">
              <>
                <div className="modal_content_text">
                  <h2 style={{ fontSize: setFontSize() }}>{data?.hiddenCode || 'L123456'}</h2>
                  <p>{intl.formatMessage({ id: 'Text code' })}</p>
                </div>
              </>
            </div>
          )}
        </>
      </ModalDlg>
    </>
  );
};

export default CodeViewModal;
