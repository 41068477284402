import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';

const deleteComma = (array: Array<boolean | string>): any => {
  if (!array[array.length - 1]) {
    return deleteComma(array.splice(0, array.length - 1));
  }

  if (!array[0]) {
    return deleteComma(array.splice(1, array.length - 1));
  }

  return array;
};

export const sliceDateTimeToDayjsUtcString = (value: Dayjs | null) => {
  return value ? dayjs(value).format('YYYY-MM-DDTHH:mm:ss.SSSZ').toString().slice(0, 23) + 'Z' : null;
};

export const sliceTimeToUtcWithBaseDate = (value: Dayjs | null) => {
  return value ? dayjs(value).format('1970-01-01THH:mm:ss.SSSZ').toString().slice(0, 23) + 'Z' : null;
};

export const transformDateTimeToDayjsUtc = (value: Dayjs | null) => {
  return value ? dayjs(value).utc() : null;
};

export const formatDays = (days: any) => {
  const ref: any = [];
  const strings: string[] = [];

  let results: string[] = [];
  let startIndex: number = 0;

  days = deleteComma(days);

  for (let i = 0; i < days.length; i++) {
    if (i === 0) {
      strings.push(days[i]);
    } else if (!days[i]) {
      strings.push(',');
    } else if (days[i - 1] && i !== days.length - 1 && days[i + 1]) {
      strings.push('-');
    } else if (days[i]) {
      strings.push(days[i]);
    } else {
      strings.push(',');
    }
  }

  for (let i = 0; i < strings.length; i++) {
    if (strings[i] === ',') {
      ref.push([strings.slice(startIndex, i)]);
      startIndex = i + 1;
    }

    if (i === strings.length - 1) {
      ref.push([strings.slice(startIndex, i + 1)]);
    }
  }

  for (let i = 0; i < ref.length; i++) {
    const array = Array.from(new Set(...ref[i]));

    if (array.includes('-')) {
      results.push(array.join(''));
    } else {
      results.push(array.join(', '));
    }
  }

  return results.join(', ');
};

export const convertToDayJsTime = (dateTime: any) => {
  return moment(dateTime).format('HH:mm') !== 'Invalid date' ? dateTime.format('HH:mm') : null;
};

export const converDateWithResetTime = (dateTime: string, isStartDay: boolean) => {
  const date = moment(dateTime).tz('America/Guatemala');

  return isStartDay
    ? date.set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
    : date.set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 999,
      });
};

export const transformDateToCurrentDate = (isoDatetimeStr: any) => {
  const oldDate = new Date(isoDatetimeStr);
  const today = new Date();
  const hours = oldDate.getUTCHours();
  const minutes = oldDate.getUTCMinutes();
  const seconds = oldDate.getUTCSeconds();
  const milliseconds = oldDate.getUTCMilliseconds();
  today.setUTCHours(hours, minutes, seconds, milliseconds);
  return today.toISOString();
};
