import { useState, useRef, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Input, Button, Switch, Checkbox } from 'antd';
import ContentCopyOutlined from '@mui/icons-material/ContentCopyOutlined';
import toast from 'components/Basic/Toast';
import { useLazyGetDealStatisticQuery } from '../../../core/deals/DealsService';

type HiddenTypes = {
  setIsShortUrl: (e: boolean) => void;
  isShortUrl: boolean;
  setIsHidden: (e: boolean) => void;
  isHidden: boolean;
  setIsSecret: (e: boolean) => void;
  isSecret: boolean;
  shortUrl: string;
  longUrl: string;
  qrCode: string;
  hiddenCode: string;
  offerId: number;
  isDraftPage: boolean;
};

const Hidden: React.FC<HiddenTypes> = ({
  setIsShortUrl,
  isShortUrl,
  setIsHidden,
  isHidden,
  setIsSecret,
  isSecret,
  shortUrl,
  longUrl,
  qrCode,
  hiddenCode,
  offerId,
  isDraftPage,
}) => {
  const [stat, setStat] = useState<any>();
  const intl = useIntl();
  const [getStats] = useLazyGetDealStatisticQuery();
  const handleRadioChange = (value: boolean) => {
    setIsShortUrl(value);
  };
  const inputRef = useRef<any>(null);

  const handleCopyClick = () => {
    if (inputRef.current) {
      const inputValue = inputRef.current.input.value; // get value from Input
      navigator.clipboard.writeText(inputValue).then(
        () => {
          toast.success({ title: intl.formatMessage({ id: 'Link Copied' }) });
        },
        () => {
          toast.error({ title: intl.formatMessage({ id: 'Link Did Not Copy' }) });
        },
      );
    }
  };

  const getStat = async () => {
    if (!offerId || isDraftPage) return;
    const _stat = await getStats({ id: offerId }).unwrap();
    setStat(_stat);
  };

  useEffect(() => {
    getStat();
  }, [offerId]);

  return (
    <>
      <div className="" id="campaign_form_wrapper">
        <div className="basic_info_form">
          <div className="tabs_input">
            <Form.Item>
              <div style={{ display: 'flex' }}>
                <p className="title_deals" style={{ marginRight: '12px', marginBottom: '0', marginTop: '0' }}>
                  <FormattedMessage id="Is hidden" />
                </p>
                <Checkbox indeterminate={isHidden} checked={isHidden} onChange={e => setIsHidden(e.target.checked)} />
              </div>
            </Form.Item>
            {isHidden && (
              <>
                {offerId && !isDraftPage ? (
                  <Form.Item>
                    <p style={{ marginTop: -10, marginBottom: 10 }} className="description">
                      {`ID ${offerId}`}
                    </p>
                    <p className="title_deals">
                      <FormattedMessage id="URL generated" />
                    </p>
                    <div style={{ display: 'flex', gap: '4px' }}>
                      <Input
                        ref={inputRef}
                        disabled={true}
                        value={isShortUrl ? shortUrl : longUrl}
                        style={{ width: '250px', marginBottom: '0' }}
                      />
                      <div className="copy_button">
                        <Button onClick={handleCopyClick} disabled={!inputRef?.current?.input?.value}>
                          <ContentCopyOutlined />
                          <FormattedMessage id="Copy" />
                        </Button>
                      </div>
                    </div>
                  </Form.Item>
                ) : (
                  <>
                    <p style={{ marginTop: -4 }} className="description">
                      <FormattedMessage id="ID will be generated after creation of offer" />
                    </p>
                    <p className="title_deals">
                      <FormattedMessage id="URL generated" />
                    </p>
                    <p style={{ marginTop: -6 }} className="description">
                      <FormattedMessage id="URL will be generated after creation of offer" />
                    </p>
                  </>
                )}
                <Form.Item>
                  <div className="aditional_detail">
                    <p id="campaign_form_switcher_label">
                      <FormattedMessage id="Shorten URL" />
                    </p>
                    <Switch checked={isShortUrl} onChange={handleRadioChange} id="campaign_form_switcher" />
                  </div>
                </Form.Item>
                <div className="statistics">
                  <p className="stat_title">
                    <FormattedMessage id="Statistics" />
                  </p>
                  <div className="stat_wrapper">
                    <p className="stat_desc">
                      <FormattedMessage id="Open URL" />
                    </p>
                    <span className="stat_num">{stat?.openUrl || 0}</span>
                  </div>
                  <div className="stat_wrapper">
                    <p className="stat_desc">
                      <FormattedMessage id="Number of Redemptions" />
                    </p>
                    <span className="stat_num">{stat?.redemptionsByUrl || 0}</span>
                  </div>
                  <div className="stat_wrapper">
                    <p className="stat_desc">
                      <FormattedMessage id="Rate" />
                    </p>
                    <span className="stat_num">{stat?.rateUrl ? `${stat?.rateUrl}%` : 0}</span>
                  </div>
                </div>
                <Form.Item>
                  <div style={{ display: 'flex' }}>
                    <p className="title_deals" style={{ marginRight: '12px', marginBottom: '0', marginTop: '0' }}>
                      <FormattedMessage id="Create QR code" />
                    </p>
                    <Checkbox
                      indeterminate={isSecret}
                      checked={isSecret}
                      onChange={e => setIsSecret(e.target.checked)}
                    />
                  </div>
                  {isSecret && (
                    <>
                      <p style={{ marginBottom: 8, marginTop: 20 }} className="title_deals">
                        <FormattedMessage id="QR Code" />
                      </p>
                      {offerId && !isDraftPage ? (
                        <div style={{ display: 'inline-block', textAlign: 'center' }}>
                          <img
                            src={qrCode}
                            alt="QR Code"
                            style={{ display: 'block', marginLeft: '0', marginRight: 'auto' }}
                          />
                          <p className="qr_code_text">
                            <FormattedMessage id={hiddenCode} />
                          </p>
                        </div>
                      ) : (
                        <p style={{ marginTop: -6 }} className="description">
                          <FormattedMessage id="QR code will be generated after creation of offer" />
                        </p>
                      )}

                      <div className="statistics" style={{ marginBottom: 0 }}>
                        <p className="stat_title">
                          <FormattedMessage id="Statistics" />
                        </p>
                        <div className="stat_wrapper">
                          <p className="stat_desc">
                            <FormattedMessage id="Scan QR" />
                          </p>
                          <span className="stat_num">{stat?.scanQrCode || 0}</span>
                        </div>
                        <div className="stat_wrapper">
                          <p className="stat_desc">
                            <FormattedMessage id="Number of Redemptions" />
                          </p>
                          <span className="stat_num">{stat?.redemptionsByQrCode || 0}</span>
                        </div>
                        <div className="stat_wrapper">
                          <p className="stat_desc">
                            <FormattedMessage id="Rate" />
                          </p>
                          <span className="stat_num">{stat?.rateQrCode ? `${stat?.rateQrCode}%` : 0}</span>
                        </div>
                      </div>
                    </>
                  )}
                </Form.Item>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Hidden;
