import { ArrowDropDown, Refresh, Search } from '@material-ui/icons';
import { Button, Input, Select } from 'antd';
import { FC, useEffect, useRef, useState, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppSelector } from '../../../utilities/redux';

const { Option } = Select;

interface IProps {
  sorts: Object[];
  statuses: Object[];
  campaigns: Object[];
  searchText: string;
  selectedStatus: string;
  selectedTab: string;
  selectedCategory: string;
  selectedCompaign: { id: string; name: string } | null;
  selectedFrom: string;
  selectedTo: string;
  selectedSortStatus: string;
  points: {
    min: number;
    max: number;
  };
  onSelectSort: (value: string) => void;
  onSelectStatus: (value: string) => void;
  onSelectCategory: (value: string) => void;
  onSelectTab: (value: string) => void;
  onSelectCampaign: (value: string, option: any) => void;
  onSelectFrom: (value: string) => void;
  onSelectTo: (value: string) => void;
  onChangeStartDate: (value: string) => void;
  onChangeEndDate: (value: string) => void;
  onChangeSearch: (e: any) => void;
  onApplyClick: () => void;
  onPointFilterValid: (value: boolean) => void;
}

const RewardsFilters: FC<IProps> = ({
  sorts,
  statuses,
  campaigns,
  searchText,
  selectedStatus,
  selectedTab,
  selectedCategory,
  selectedCompaign,
  selectedFrom,
  selectedTo,
  selectedSortStatus,
  points = {
    min: 0,
    max: 100,
  },
  onSelectSort,
  onSelectStatus,
  onSelectCategory,
  onSelectTab,
  onSelectCampaign,
  onSelectFrom,
  onSelectTo,
  onChangeStartDate,
  onChangeEndDate,
  onChangeSearch,
  onApplyClick,
  onPointFilterValid,
}: IProps) => {
  const intl = useIntl();
  const pointsWrapperRef = useRef<HTMLDivElement>(null);
  const filterButtonRef = useRef<HTMLDivElement>(null);
  const { rewardsPreview } = useAppSelector(state => state.rewardsReducer);

  const sortOptions = sorts.map((item: any) => {
    let isDisabled = false;

    if (selectedStatus === 'false' || selectedStatus === 'true') {
      isDisabled = item.value !== 'Ndesc' && item.value !== 'Nasc' && item.value !== 'Idesc' && item.value !== 'Iasc';

      // && item.value !== 'Wasc' &&
      // item.value !== 'Wdesc';
    }

    return (
      <Option
        value={item.value}
        key={item.label}
        disabled={isDisabled}
        style={{ fontFamily: 'Lato', color: '#242c40' }}
      >
        {item.label}
      </Option>
    );
  });

  const filterOptions = statuses.map((item: any) => {
    let isDisabled = false;

    if (selectedSortStatus === 'Sdesc' || selectedSortStatus === 'Sasc') {
      isDisabled = item.value !== '';
    }

    return (
      <Option
        value={item.value}
        key={item.label}
        disabled={isDisabled}
        style={{ fontFamily: 'Lato', color: '#242c40' }}
      >
        {item.label}
      </Option>
    );
  });

  const onStartDateChange = (date: any, dateString: string) => {
    onChangeStartDate(dateString);
  };

  const onEndDateChange = (date: any, dateString: string) => {
    onChangeEndDate(dateString);
  };

  const [fromValue, setFromValue] = useState<string>('');
  const [toValue, setToValue] = useState<string>('');
  const [isShowPoint, setShowPoints] = useState<boolean>(false);

  const handleClickOutside = (event: any) => {
    if (
      pointsWrapperRef.current &&
      !pointsWrapperRef.current.contains(event.target) &&
      filterButtonRef.current &&
      !filterButtonRef.current.contains(event.target)
    ) {
      setShowPoints(false);
    }
  };

  const handleNumericInput = (value: string, setValue: (value: string) => void) => {
    if (/^\d{0,9}$/.test(value)) {
      if (value === '' || /^[1-9]\d{0,8}$|^0$/.test(value)) {
        setValue(value || '');
      }
    }
  };

  const MIN_POINTS = 0;
  const MAX_POINTS = 999999999;

  const minMaxPoints = useMemo(() => {
    const minPoints = points.min;
    const maxPoints = points.max;

    const selectedFromValue = minPoints === Infinity ? MIN_POINTS : minPoints;
    const selectedToValue = maxPoints === -Infinity ? MAX_POINTS : maxPoints;

    return { selectedFromValue, selectedToValue };
  }, [rewardsPreview]);

  const getPointsRange = () => {
    if (!(selectedFrom || selectedTo)) {
      return intl.formatMessage({ id: 'Points' });
    }
    return `${selectedFrom || minMaxPoints.selectedFromValue} - ${selectedTo || minMaxPoints.selectedToValue}`;
  };

  const isPointsValid = () => {
    const { selectedFromValue, selectedToValue } = minMaxPoints;
    const fromValue = selectedFrom || selectedFromValue;
    const toValue = selectedTo || selectedToValue;
    onPointFilterValid(+toValue >= +fromValue);
    return +toValue >= +fromValue;
  };

  useEffect(() => {
    if (isShowPoint) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isShowPoint]);

  return (
    <div className="rewards_filter_layout" id="rewards_filter_layout">
      <div className="availabilities_containers">
        <Select
          id="campaign_status_filter"
          className="availability_by"
          suffixIcon={<ArrowDropDown />}
          placeholder={intl.formatMessage({ id: 'Status' })}
          value={selectedStatus}
          onChange={onSelectStatus}
          dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
        >
          {filterOptions}
        </Select>
        <Select
          className="availability_by"
          suffixIcon={<ArrowDropDown />}
          placeholder={intl.formatMessage({ id: 'Campaign' })}
          onChange={onSelectCampaign}
          value={selectedCompaign ? selectedCompaign.name : undefined}
          dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
        >
          <Option value="" key="allCategories">
            {intl.formatMessage({ id: 'All campaigns' })}
          </Option>
          {campaigns &&
            campaigns.map((item: any) => (
              <Option value={item.name} key={item.id}>
                {item.name}
              </Option>
            ))}
        </Select>
        {/* <DatePicker
          className="start_date"
          placeholder="Fecha de inicio"
          onChange={onStartDateChange}
          suffixIcon={<DateRange />}
        />
        <DatePicker
          className="start_date"
          placeholder="Fecha límite"
          onChange={onEndDateChange}
          suffixIcon={<DateRange />}
        /> */}
        <div
          className={`points_filter  ${!isPointsValid() && 'points_error'}`}
          ref={filterButtonRef}
          onClick={e => {
            e.stopPropagation();
            setShowPoints(!isShowPoint);
          }}
        >
          <p className={`points_placeholder ${(selectedFrom || selectedTo) && 'points_text'}`}>{`${getPointsRange()} ${
            selectedFrom || selectedTo ? intl.formatMessage({ id: 'Points' }) : ''
          }`}</p>
          <ArrowDropDown />
        </div>
        {isShowPoint && (
          <div className="points_filter_wrapper" ref={pointsWrapperRef}>
            <div className="filter_fields">
              <div className="point_desc">{intl.formatMessage({ id: 'FromPoints' })}</div>
              <Input
                className={`points_input ${selectedFrom && 'points_text'}  ${!isPointsValid() && 'points_error'}`}
                placeholder={String(minMaxPoints.selectedFromValue)}
                value={selectedFrom}
                onChange={e => handleNumericInput(e.target.value, onSelectFrom)}
              />
            </div>
            <div className="filter_fields">
              <div className="point_desc">{intl.formatMessage({ id: 'ToPoints' })}</div>
              <Input
                className={`points_input ${selectedTo && 'points_text'}  ${!isPointsValid() && 'points_error'}`}
                placeholder={String(minMaxPoints.selectedToValue)}
                value={selectedTo}
                onChange={e => handleNumericInput(e.target.value, onSelectTo)}
              />
            </div>
          </div>
        )}
        <Select
          className="sort_by"
          suffixIcon={<ArrowDropDown />}
          placeholder={intl.formatMessage({ id: 'Sort by' })}
          onChange={onSelectSort}
          dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
        >
          {sortOptions}
        </Select>
        <Button id="rewards_apply_filters_btn" className="apply_filters_btn" onClick={onApplyClick}>
          <FormattedMessage id="Apply filters" />
          <Refresh />
        </Button>
      </div>
      <div className="search_container">
        <Input
          suffix={<Search />}
          placeholder={'Buscar recompensa'}
          value={searchText}
          onChange={onChangeSearch}
          onKeyDown={onChangeSearch}
          id="rewards_search_input"
          maxLength={50}
        />
      </div>
    </div>
  );
};

export default RewardsFilters;
