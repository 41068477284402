import { Edit, RemoveRedEye } from '@material-ui/icons';
import { Button, Pagination, Tooltip } from 'antd';
import toast from 'components/Basic/Toast';
import RewardsViewModal from 'components/Dashboard/Rewards/RewardViewModal';
import { useCreateOffersMutation, useDeleteOffersMutation, useLazyGetFullOffersQuery } from 'core/offers/OffersService';
import { setDeleteOffer } from 'core/offers/OffersSlice';
import { setRewardData } from 'core/rewards/RewardsSlice';
import { useGetSubtypesQuery, useLazyGetOneRewardQuery } from 'core/rewards/RewarsService';
import React, { useEffect, useState } from 'react';
import { Img } from 'react-image';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import CodeViewModal from './CodeViewModal';

type RewardsTable = {
  total: number;
  current: number;
  rewardId?: string;
  rewardData: any | null;
  onChangePage: (current: number, size: number) => void;
  setCurrent: (e: number) => void;
};

const RewardsTable: React.FC<RewardsTable> = ({
  total,
  current,
  rewardId = '',
  rewardData,
  setCurrent,
  onChangePage,
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [pageSize, setPageSize] = useState<number>(10);
  const [_, setIsLoading] = useState<boolean>(false);

  const [offersInfo, setOffersInfo] = useState<any>(null);
  // const [isRemoveModal, setIsRemoveModal] = useState<boolean>(false);
  const [isShowCodeModal, setShowCodeModal] = useState<boolean>(false);
  const [isShowDetailModal, setShowDetailModal] = useState<boolean>(false);

  // const [deleteOffers] = useDeleteOffersMutation();
  // const [getOfferInfo] = useLazyGetFullOffersQuery();
  // const [createOffers] = useCreateOffersMutation();

  const [getOneReward] = useLazyGetOneRewardQuery();
  const { data: subtypes, refetch } = useGetSubtypesQuery({}, { refetchOnFocus: true });

  const { rewardFulldData } = useAppSelector(state => state.rewardsReducer);

  useEffect(() => {
    onChangePage(1, 10);
    if (rewardId) {
      showOfferFromUrl(rewardId);
    }
  }, []);

  const getSubtypeName = (reward: { subtabs: number[] }) => {
    if (reward.subtabs.length === 0 || subtypes.lenght === 0) {
      return '';
    }

    const subtabName = subtypes.find((tab: any) => tab.id === reward.subtabs[0]).name;
    return subtabName || '';
  };

  const handleChangePage = (page: number, size: number) => {
    setCurrent(page);
    setPageSize(size);
    onChangePage(page, size);
  };

  const handleShowSizeChange = (c: number, s: number) => {
    handleChangePage(c, s);
  };

  const handleInfoOffer = async (item: any) => {
    setIsLoading(true);
    try {
      dispatch(setRewardData({ rewardFulldData: null }));
      const res = await getOneReward({ id: item.id }).unwrap();
      dispatch(setRewardData(res));
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const showOfferFromUrl = async (id: any) => {
    try {
      if (!id) return;

      const res = await getOneReward({ id: id }).unwrap();

      setOffersInfo(res);
      handleInfoOffer(res);
      setShowDetailModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="rewards_table_layout">
      <div className="table_header">
        <div className="id">
          <FormattedMessage id="ID" />
        </div>
        <div className="image">
          <FormattedMessage id="Image" />
        </div>
        <div className="name">
          <FormattedMessage id="Name" />
        </div>
        <div className="descriptions">
          <FormattedMessage id="Description" />
        </div>
        <div className="product">
          <FormattedMessage id="Product ID" />
        </div>
        <div className="sar">
          <FormattedMessage id="SAR ID" />
        </div>
        <div className="subtype">
          <FormattedMessage id="Subtype" />
        </div>
        <div className="points">
          <FormattedMessage id="Points" />
        </div>
        <div className="weight">
          <FormattedMessage id="Weight" />
        </div>
        <div className="actions">
          <FormattedMessage id="Actions" />
        </div>
      </div>
      <div>
        <div className="table_content">
          {Array.isArray(rewardData) &&
            rewardData.map((item, index) => (
              <div className={`table_item type_state_${item.isActive}`} key={index}>
                <div className="id">
                  <p>{item.id}</p>
                </div>
                <div className="image">
                  <div className="image_container">
                    <Img src={item.image1} alt="reward" />
                  </div>
                </div>
                <div className="name">
                  <p>{item.name}</p>
                </div>
                <div className="descriptions">
                  <p>{item.description}</p>
                </div>
                <div className="product">
                  <p>{item.productId}</p>
                </div>
                <div className="sar">
                  <p>{item.sarId}</p>
                </div>
                <div className="subtype">
                  <p>{item.subtabs?.length ? (subtypes ? getSubtypeName(item) : '') : ''}</p>
                </div>
                <div className="points">{item.points}</div>
                <div className="weight">
                  <p>{item.weight}</p>
                </div>
                <div className="actions">
                  <Tooltip placement="top" title={intl.formatMessage({ id: 'View reward' })}>
                    <Button
                      className="edit_btn"
                      onClick={() => {
                        setShowCodeModal(true);
                        setOffersInfo(item);
                        handleInfoOffer(item);
                      }}
                    >
                      <RemoveRedEye />
                    </Button>
                  </Tooltip>
                  <NavLink to={`/dashboard/deals/rewards/${item.id}`} style={{ marginRight: '10px' }}>
                    <Tooltip placement="top" title={intl.formatMessage({ id: 'Edit Reward' })}>
                      <Button
                        className="edit_btn"
                        onClick={() => {
                          setOffersInfo(item);
                          setShowDetailModal(true);
                          handleInfoOffer(item);
                        }}
                      >
                        <Edit />
                      </Button>
                    </Tooltip>
                  </NavLink>
                </div>
              </div>
            ))}
        </div>
        <div className="pagination_wrapper">
          <Pagination
            showSizeChanger
            defaultCurrent={1}
            defaultPageSize={10}
            current={current}
            pageSize={pageSize}
            pageSizeOptions={['5', '10', '20', '30']}
            total={total}
            onChange={handleChangePage}
            onShowSizeChange={handleShowSizeChange}
          />
          <div className="total">Resultados: {total}</div>
        </div>
      </div>
      {rewardFulldData?.id && (
        <RewardsViewModal
          isShowModal={isShowDetailModal}
          onShowModal={(value: boolean) => setShowDetailModal(value)}
          rewardInfo={rewardFulldData}
          current={current}
          pageSize={pageSize}
          draftRequest={() => {}}
          categories={null}
          offersRequest={handleChangePage}
        />
      )}
      {rewardFulldData?.id && (
        <CodeViewModal
          isOpenModal={isShowCodeModal}
          onCloseModal={() => {
            setShowCodeModal(false);
          }}
          data={rewardFulldData}
          openOfferModal={() => setShowDetailModal(true)}
        />
      )}
    </div>
  );
};

export default RewardsTable;
