import { Input } from 'antd';
import { FormattedMessage } from 'react-intl';

type RedemptionType = {
  isValid: boolean;
  minutes: number;
  setMinutes: (value: any) => void;
};

const Redemption: React.FC<RedemptionType> = ({ isValid, minutes, setMinutes }) => {
  const handleMinutesChange = (e: any) => {
    const onlyNumbers = e.target.value.replace(/[^0-9]/g, '');
    setMinutes(Number(onlyNumbers.trimStart()));
  };
  return (
    <>
      <p className="title_deals">
        <FormattedMessage id="Code Expiry in Minutes" />
      </p>
      <Input
        placeholder=""
        type="number"
        value={minutes || ''}
        onChange={handleMinutesChange}
        maxLength={200}
        min={1}
        style={{ border: (!isValid && minutes < 1) || minutes > 60 ? '1px solid red' : '' }}
      />
      {!isValid && minutes < 1 && (
        <span className="error_msg">
          <FormattedMessage id="Field is required" />
        </span>
      )}
      {minutes > 60 && (
        <span className="error_msg">
          <FormattedMessage id="Minutes value error" />
        </span>
      )}
    </>
  );
};
export default Redemption;
