import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, useLocation, useHistory } from 'react-router-dom';
import toast from 'components/Basic/Toast';
import NavBar from 'containers/Dashboard/NavBar';
import Overview from 'containers/Dashboard/Overview';
import Orders from 'containers/Dashboard/Orders';
import Restaurants from 'containers/Dashboard/Restaurants';
import Catalogue from 'containers/Dashboard/Catalogue';
import Users from 'containers/Dashboard/Users';
import OtsAgents from 'containers/Dashboard/OtsAgents';
import Ambassadors from 'containers/Dashboard/Ambassadors';
import Distribution from 'containers/Dashboard/Distribution';
import OperatorAgents from 'containers/Dashboard/OperatorAgents';
import Notificationes from 'containers/Dashboard/Notificationes';
import Deals from 'containers/Dashboard/Deals/Deals';
import Tabs from 'containers/Dashboard/Deals/Tabs';
import Categories from 'containers/Dashboard/Deals/Categories';
import Segments from 'containers/Dashboard/Deals/Segments';
import Promotions from 'containers/Dashboard/Promotions';
import Coupons from 'containers/Dashboard/Coupons';
import Analytical from 'containers/Dashboard/Analytical/Analytical';
import ServiceStatus from 'containers/Dashboard/ServiceStatus';
import SarConfig from 'containers/Dashboard/SarConfig';
import Banner from 'containers/Dashboard/Banner';
import Loyalty from 'containers/Dashboard/Loyalty';
import PageNotFound from 'components/PageNotFound/PageNotFound';
import { authenticationErrorCodes, authorizationErrorCodes } from '../../utilities/constants';

import { useLazyGetProfileQuery, useLogoutMutation } from 'core/auth/LoginService';
import { useAppSelector, useAppDispatch } from 'utilities/redux';
import { setAdminData, setInitState } from 'core/auth/LoginSlice';
import Rewards from './Rewards/Rewards';

const Dashboard = () => {
  const [previousPath, setPreviousPath] = useState('');
  const location = useLocation();
  const [profile] = useLazyGetProfileQuery();
  const [logout] = useLogoutMutation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { admin } = useAppSelector(state => state.loginReducer);

  useEffect(() => {
    if (previousPath !== location.pathname) {
      getProfile();
    }
    setPreviousPath(location.pathname);
  }, [location.pathname]);

  const getProfile = async () => {
    if (admin) {
      try {
        const res = await profile().unwrap();
        dispatch(
          setAdminData({
            admin: { ...res, ...admin },
          }),
        );
      } catch (error) {
        toast.error({
          title: 'Parece que algo salió mal, por favor, inténtalo de nuevo en un momento',
        });
        if (
          (error.data && authenticationErrorCodes.includes(error.data.code)) ||
          (error.data && authorizationErrorCodes.includes(error.data.code)) ||
          (error.error && error.error.message === 'Failed to fetch')
        ) {
          document.cookie = 'connect.sid=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
          await logout({})
            .unwrap()
            .then(() => {
              dispatch(setInitState());
              history.push('/');
            });
        }
      }
    }
  };

  return (
    <div className="dashboard_layout">
      <div className="dashboard_content">
        <NavBar admin={admin} />
        <div className="content">
          <Switch>
            <Route exact path="/dashboard/overview" component={Overview} />
            {admin.orderAccess && <Route exact path="/dashboard/orders" component={Orders} />}
            {admin.restaurantAccess && (
              <Route
                path={[
                  '/dashboard/restaurants',
                  '/dashboard/restaurants/deliveryzones',
                  '/dashboard/restaurants/new',
                  '/dashboard/restaurants/:id',
                  '/dashboard/restaurants/:rid/deliveryzone',
                  '/dashboard/restaurants/:rid/deliveryzone/:pageType',
                ]}
                component={Restaurants}
              />
            )}
            {admin.catalogAccess && (
              <Route path={['/dashboard/catalogue', '/dashboard/catalogue/:pageType']} component={Catalogue} />
            )}
            {admin.customerAccess && (
              <Route exact path={['/dashboard/users', '/dashboard/users/:pageType']} component={Users} />
            )}
            {admin.agentAccess && (
              <Route
                exact
                path={['/dashboard/ots', '/dashboard/ots/:pageType', '/dashboard/ots/:id']}
                component={OtsAgents}
              />
            )}
            {admin.ambassadorAccess && (
              <Route
                exact
                path={['/dashboard/ambassadors', '/dashboard/ambassadors/:pageType', '/dashboard/ambassadors/:id']}
                component={Ambassadors}
              />
            )}
            {admin.ambassadorAccess && (
              <Route
                exact
                path={['/dashboard/distribution', '/dashboard/distribution/:pageType', '/dashboard/distribution/:id']}
                component={Distribution}
              />
            )}
            {admin.operatorAccess && (
              <Route
                exact
                path={['/dashboard/operators', '/dashboard/operators/:pageType', '/dashboard/operators/:id']}
                component={OperatorAgents}
              />
            )}
            {admin.notificationAccess && <Route exact path="/dashboard/notificationes" component={Notificationes} />}
            <Route exact path="/dashboard/loyalty" component={Loyalty} />
            {admin.dealAccess && (
              <Route
                exact
                path={['/dashboard/deals/offers/tabs', '/dashboard/offers/tabs/:pageType']}
                component={Tabs}
              />
            )}
            {admin.dealAccess && (
              <Route
                exact
                path={['/dashboard/deals/offers/segments', '/dashboard/offers/segments/:pageType']}
                component={Segments}
              />
            )}
            {admin.dealAccess && (
              <Route
                exact
                path={['/dashboard/deals/offers/categories', '/dashboard/offers/categories/:pageType']}
                component={Categories}
              />
            )}
            {admin.dealAccess && (
              <Route
                exact
                path={[
                  '/dashboard/deals',
                  '/dashboard/deals/:pageType',
                  '/dashboard/deals/offers/:offerId',
                  '/dashboard/deals/offers-draft',
                  '/dashboard/deals/offers-draft/:draftId',
                  '/dashboard/deals/rewards/:rewardId',
                ]}
                component={Deals}
              />
            )}
            {admin.dealAccess === false && admin.rewardsAccess == true && (
              <Route
                exact
                path={['/dashboard/deals', '/dashboard/deals/:pageType', '/dashboard/deals/rewards/:rewardId']}
                component={Deals}
              />
            )}
            {admin.rewardsAccess && (
              <Route
                exact
                path={['/dashboard/deals/rewards', '/dashboard/deals/rewards/:rewardId']}
                component={Rewards}
              />
            )}
            {admin.promotionAccess && <Route exact path="/dashboard/promotions" component={Promotions} />}
            {admin.couponAccess && <Route exact path="/dashboard/coupons" component={Coupons} />}
            {admin.analyticsAccess && (
              <Route exact path={['/dashboard/analytical', '/dashboard/analytical/:pageType']} component={Analytical} />
            )}
            <Route exact path="/dashboard/status" component={ServiceStatus} />
            <Route exact path="/dashboard/sar" component={SarConfig} />
            {admin.settingAccess && <Route exact path="/dashboard/banner" component={Banner} />}
            <Route exact path="/404" component={PageNotFound} />
            <Redirect to="/404" />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
