import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Input, Select, Radio, Checkbox, TimePicker, Tooltip } from 'antd';
import { ArrowDropDown } from '@material-ui/icons';
import dayjs, { Dayjs } from 'dayjs';
import InfoIcon from '@mui/icons-material/Info';

type RedemptionsPartType = {
  isValid: boolean;
  isLimit: string;
  minutes: string;
  offers: number;
  days: number;
  time: Dayjs | null;
  limit: number;
  isRepeatable: boolean;
  selectedFilterCode: number;
  numberOfUsed: number;
  setMinutes: (value: any) => void;
  setOffers: (value: any) => void;
  setDays: (value: any) => void;
  setTime: (value: Dayjs | null) => void;
  setLimit: (value: any) => void;
  setIsLimit: (value: any) => void;
  setIsRepeatable: (value: any) => void;
  setSelectedFilterCode: (value: any) => void;
};

const RedemptionsPart: React.FC<RedemptionsPartType> = ({
  isValid,
  isLimit,
  minutes,
  offers,
  days,
  time,
  limit,
  isRepeatable,
  selectedFilterCode,
  numberOfUsed,
  setMinutes,
  setOffers,
  setDays,
  setTime,
  setLimit,
  setIsLimit,
  setIsRepeatable,
  setSelectedFilterCode,
}) => {
  const intl = useIntl();
  const sortOptions = [{ value: 'short_code', label: intl.formatMessage({ id: 'Short code' }) }];

  const handleMinutesChange = (e: any) => {
    setMinutes(Number(e.target.value.trimStart()));
  };

  const handleDaysChange = (e: any) => {
    setDays(Number(e.target.value.trimStart()));
  };

  const onTimeChange = (value: Dayjs | null) => {
    if (value) {
      setTime(dayjs(value).set('s', 0).set('ms', 0));
    } else {
      setTime(null);
    }
  };

  const handleOffersChange = (e: any) => {
    setOffers(Number(e.target.value.trimStart()));
  };

  const handleLimitChange = (e: any) => {
    setLimit(Number(e.target.value.trimStart()));
  };

  const handleSort = (value: number) => {
    setSelectedFilterCode(value);
  };
  const handleRadioChange = (value: number) => {
    setOffers(0);
    setIsLimit(value);
  };
  const checkField = (value: any, compare?: 'days' | 'limit') => {
    if (isValid) return;
    if (compare === 'days') {
      return value === 0 && limit === 0 ? true : false;
    }
    if (compare === 'limit') {
      return value === 0 && days === 0 ? true : false;
    }
    if (value) {
      const valid = value.length > 0 || value;
      return !valid;
    } else {
      return true;
    }
  };
  return (
    <>
      <div className="" id="campaign_form_wrapper">
        <div className="basic_info_form">
          <div className="tabs_input">
            <Form.Item>
              <div style={{ marginBottom: '20px' }}>
                <p className="title_deals">
                  <FormattedMessage id="Redemption Code Type" />
                </p>
                <Select
                  className="select_modal"
                  suffixIcon={<ArrowDropDown />}
                  onChange={handleSort}
                  id="campaign_status_filter"
                  value={selectedFilterCode}
                  dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                >
                  {sortOptions.map(item => (
                    <Select.Option value={item.value} key={item.label} style={{ fontFamily: 'Lato', color: '#242c40' }}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
                {checkField(selectedFilterCode) && (
                  <span className="error_msg">
                    <FormattedMessage id="Field is required" />
                  </span>
                )}
              </div>

              <p className="title_deals">
                <FormattedMessage id="Code Expiry in Minutes" />
              </p>
              <Input
                placeholder=""
                type="number"
                value={minutes || ''}
                onChange={handleMinutesChange}
                maxLength={200}
                min={1}
                className={checkField(minutes) || Number(minutes) > 60 ? 'has-error' : ''}
              />
              {checkField(minutes) && (
                <span className="error_msg">
                  <FormattedMessage id="Field is required" />
                </span>
              )}
              {Number(minutes) > 60 && (
                <span className="error_msg">
                  <FormattedMessage id="Minutes value error" />
                </span>
              )}
              <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                <p className="title_deals">
                  <FormattedMessage id="Characteristic" />
                </p>
                <Radio.Group onChange={e => handleRadioChange(e.target.value)} value={isLimit}>
                  <Radio value={'unlimited'}>{intl.formatMessage({ id: 'Unlimited' })}</Radio>
                  <Radio value={'limited'}>{intl.formatMessage({ id: 'Limited Number' })}</Radio>
                </Radio.Group>
              </div>
              {isLimit === 'limited' && (
                <>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p className="title_deals">
                      <FormattedMessage id="Number of available offers" />
                    </p>
                    {numberOfUsed >= 0 && (
                      <span className="title_description">{`(${numberOfUsed} ofertas canjeadas)`}</span>
                    )}
                  </div>
                  <Input
                    placeholder=""
                    type="number"
                    value={offers || ''}
                    onChange={handleOffersChange}
                    maxLength={200}
                    min={0}
                    className={checkField(offers) ? 'has-error' : ''}
                  />
                  {checkField(offers) && (
                    <span className="error_msg">
                      <FormattedMessage id="Field is required" />
                    </span>
                  )}
                </>
              )}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: isLimit === 'limited' ? '20px' : '10px',
                }}
              >
                <p className="title_deals" style={{ marginRight: '12px', marginBottom: '0', marginTop: '0' }}>
                  <FormattedMessage id="Is repeatable" />
                </p>
                <Checkbox
                  indeterminate={isRepeatable}
                  checked={isRepeatable}
                  onChange={e => {
                    setIsRepeatable(e.target.checked);
                    setDays(0);
                    setTime(null);
                    setLimit(0);
                  }}
                />
                <Tooltip placement="top" title={intl.formatMessage({ id: 'Limit message' })}>
                  <InfoIcon color="disabled" style={{ marginLeft: '4px' }} />
                </Tooltip>
              </div>
              {isRepeatable && (
                <div className="repeatableInputs">
                  <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                    <p className="title_deals">
                      <FormattedMessage id="Can Be Redeemed Again in the Following Number of Days" />
                    </p>
                    <Input
                      placeholder=""
                      type="number"
                      value={days || 0}
                      onChange={handleDaysChange}
                      maxLength={200}
                      min={0}
                      className={checkField(days, 'days') ? 'has-error' : ''}
                    />
                    {checkField(days, 'days') && (
                      <span className="error_msg">
                        <FormattedMessage id="Field is required" />
                      </span>
                    )}
                  </div>

                  <div style={{ marginBottom: '20px' }}>
                    <p className="title">
                      <FormattedMessage id="Repeatable Start Time" />
                    </p>
                    <TimePicker
                      className={`repeatTime`}
                      format="HH:mm"
                      placeholder="00:00"
                      value={time ? dayjs(time, 'HH:mm') : null}
                      onChange={onTimeChange}
                      minuteStep={10}
                    />
                  </div>

                  <div style={{ marginBottom: 0 }}>
                    <p className="title_deals">
                      <FormattedMessage id="Limit Number of Times Offer Will Repeat" />
                    </p>
                    <Input
                      placeholder=""
                      type="number"
                      value={limit || 0}
                      onChange={handleLimitChange}
                      maxLength={200}
                      min={0}
                      className={checkField(limit, 'limit') ? 'has-error' : ''}
                    />
                    {checkField(limit, 'limit') && (
                      <span className="error_msg">
                        <FormattedMessage id="Field is required" />
                      </span>
                    )}
                  </div>
                </div>
              )}
            </Form.Item>
          </div>
        </div>
      </div>
    </>
  );
};

export default RedemptionsPart;
